import React from 'react';

export const useModalController = () => {
  const [isOpen, setOpen] = React.useState(false);

  const openModal = React.useCallback(() => {
    setOpen(true);
  }, []);

  const closeModal = React.useCallback(() => {
    setOpen(false);
  }, []);

  return { isOpen, openModal, closeModal };
};
