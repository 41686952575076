import * as React from 'react';
import styled from 'styled-components';
import cl from 'classnames';

import { sizeStyles } from './sizes';
import { colorsV2 } from 'style/colors-v2';

const Container = styled.div`
  background-color: ${colorsV2.gray10};
  border-radius: 8px;

  ${sizeStyles}

  &.with-action {
    cursor: pointer;
  }

  &:hover {
    background-color: ${colorsV2.gray20};
  }

  &:focus,
  &:focus-within,
  &.active {
    background-color: ${colorsV2.gray10};
    outline: 2px solid ${colorsV2.blue80};
  }

  &.error {
    background-color: ${colorsV2.gray10};
    outline: 2px solid ${colorsV2.red80};
  }

  &.disabled {
    background-color: ${colorsV2.gray40};
    outline: none;
    cursor: not-allowed;

    input {
      color: ${colorsV2.black60};
    }
  }
`;

export const InputGreyStyleLayout = React.forwardRef((props, ref) => {
  const {
    className,
    inputContent,
    size = 'medium',
    active,
    error,
    disabled,
    onClick,
    ...rest
  } = props;

  return (
    <Container
      ref={ref}
      className={cl('input-grey-style-layout', className, size, {
        active,
        error,
        disabled,
        'with-action': Boolean(onClick)
      })}
      onClick={onClick}
      {...rest}
    >
      {inputContent}
    </Container>
  );
});
