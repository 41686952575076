import * as React from 'react';
import styled from 'styled-components';
import cl from 'classnames';

import { typeStyles } from './typeStyles';
import IconEssentialClose from 'components/Icon/SVGIcons/IconEssentialClose';
import { flexGap } from 'style/css-style';

const CloseButton = styled.div`
  display: flex;

  padding: 2px;
  cursor: pointer;
`;

const Info = styled.div`
  flex: 1;
  white-space: pre-wrap;

  .text {
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    margin-bottom: 0;
  }
`;

const IconWrapper = styled.div`
  display: flex;
  padding: 2px 0;
`;

const Container = styled.div`
  display: flex;
  align-items: center;
  ${flexGap(10)}

  padding: 8px 12px 8px 14px;
  border-radius: 8px;

  &.with-action {
    cursor: pointer;
  }

  &.start {
    align-items: flex-start;
  }

  &.end {
    align-items: flex-end;
  }

  ${typeStyles}
`;

export const AlertSimple = props => {
  const {
    type,
    align = 'center',
    icon,
    text,
    bordered = false,
    closable = false,
    onClick,
    onClose
  } = props;

  return (
    <Container
      className={cl('alert-simple', type, align, {
        'with-border': bordered,
        'with-action': Boolean(onClick)
      })}
      onClick={onClick}
    >
      {icon && <IconWrapper className="icon">{icon}</IconWrapper>}
      <Info>
        {typeof text === 'string' ? (
          <p className="text" dangerouslySetInnerHTML={{ __html: text }} />
        ) : (
          text
        )}
      </Info>
      {closable && (
        <CloseButton
          className="close-button"
          onClick={e => {
            e.stopPropagation();
            onClose?.();
          }}
        >
          <IconEssentialClose width={16} height={16} />
        </CloseButton>
      )}
    </Container>
  );
};
