import React from 'react';
import { InputContentGeneral } from 'shared/InputContentGeneral/InputContentGeneral';
import { InputGreyStyleLayout } from 'shared/InputGreyStyleLayout/InputGreyStyleLayout';
import { InputGroupGreyStyleLayout } from 'shared/InputGroupGreyStyleLayout/InputGroupGreyStyleLayout';
import { InputItemLabel } from 'shared/InputItemLabel/InputItemLabel';

const LastNameItem = props => {
  const { value, onChange, disabled = false } = props;
  return (
    <InputGroupGreyStyleLayout
      className="form-item-last-name"
      labelContent={<InputItemLabel text="Họ Phụ huynh" required hasColon />}
      inputContent={
        <InputGreyStyleLayout
          size="large"
          inputContent={
            <InputContentGeneral
              placeholder="Nhập họ"
              value={value}
              onChange={onChange}
              disabled={disabled}
            />
          }
        />
      }
    />
  );
};

export default LastNameItem;
