import * as React from 'react';
import styled from 'styled-components';
import cl from 'classnames';
import { colorsV2 } from 'style/colors-v2';

const Container = styled.div`
  padding: 10px 12px;
  background-color: ${colorsV2.white100};
  cursor: pointer;

  &:hover:not(.disabled) {
    background-color: ${colorsV2.neutral10};
  }

  &.divider {
    border-bottom: 1px solid ${colorsV2.neutral20};
  }

  &.selected {
    background-color: ${colorsV2.blue10};
  }

  &.readonly,
  &.disabled {
    cursor: initial;
  }
`;

export const DropdownItemItemLayout = props => {
  const {
    className,
    selected = false,
    readonly = false,
    divider = false,
    disabled = false,
    content,
    ...rest
  } = props;

  return (
    <Container
      className={cl('dropdown-item-item-layout', className, {
        selected,
        divider,
        readonly,
        disabled
      })}
      {...rest}
    >
      {content}
    </Container>
  );
};
