import * as React from 'react';
import styled from 'styled-components';

import { colorsV2 } from 'style/colors-v2';
import { Typography } from 'components/DesignSystemV2/Typography';
import { withHomePageSection } from 'components/withHomePageSection';
import { fromScreen } from 'utils/media-query/responsive.util';

import { BreadCrumb } from 'shared/CourseLanding/Detail/BreadCrumb';
import { FixedTopBarScholarshipScheduleNOffline } from 'shared/SchedulePage/FixedTopBarScholarship/FixedTopBarScholarshipScheduleNOffline';

const anotherCourseDesc = {
  SAT: 'Khoá học tập trung vào việc thay đổi Tư duy, Áp dụng Linearthinking giảm sự phụ thuộc vào từ vựng. Cấp tốc đạt mục tiêu SAT từ 1300 - 1500+ trong vòng 9 tuần',
  TOEIC:
    'Khoá học tập trung vào việc củng cố và mở rộng thêm nền tảng kiến thức cơ bản cho học viên, đồng thời cho học viên làm quen và áp dụng Linearthinking để chuẩn bị tiếp thu những kiến thức chuyên sâu hơn. Đạt mục tiêu TOEIC từ 550-750+ chỉ sau 24 buổi',
  'Phát âm':
    'Khoá học tập trung vào việc củng cố lại nền tảng kiến thức Tiếng Anh cơ bản cho học viên, đồng thời cho học viên làm quen với các chủ điểm giao tiếp phổ biến. Từ đó hình thành phản xạ giao tiếp, biết cách đọc tình huống giao tiếp và giao tiếp 1 cách tự nhiên',
  'GMAT/GRE':
    'Khoá học GMAT/GRE của DOL tập trung vào việc phát huy nền tảng tiếng Anh để tiếp cận kì thi GMAT/GRE hiệu quả, phát triển tư duy logic, tư duy phản biện để tăng điểm tối đa. Từ đó, cấp tốc đạt mục tiêu GMAT/GRE chỉ sau 1 khóa học.'
};

const Main = styled.div`
  #course-landing-detail-header-slot {
    display: none;
  }
  padding: 32px 0;

  .title {
    margin-top: 32px;
    display: grid;
    gap: 4px;
    max-width: 688px;
  }
  .breadcrumb {
    a:last-child > p {
      color: ${colorsV2.black80};
    }
  }
`;

const Wrapper = styled.div`
  ${fromScreen(1144)} {
    #fixed-topbar-scholarship {
      display: none;
    }
    ${Main} {
      .section {
        display: grid;
        grid-template-columns: 1fr 340px;
        gap: 28px;
      }
      #course-landing-detail-header-slot {
        display: block;
        margin-top: -16px;
      }
    }
  }
`;

export const HeaderWithPromotion = ({ level }) => (
  <Wrapper>
    <FixedTopBarScholarshipScheduleNOffline />
    <Header level={level} />
  </Wrapper>
);

const Header = withHomePageSection(
  ({ level }) => {
    return (
      <Main>
        <BreadCrumb
          links={[
            {
              text: 'Trang chủ',
              to: '/',
              tabletOnly: true
            },
            {
              text: 'Khoá học IELTS',
              to: '/khoa-hoc-ielts',
              tabletOnly: true
            },
            {
              text: 'Khóa học khác',
              to: `/khoa-hoc-khac`
            },
            {
              text: level,
              to: '/'
            }
          ]}
        />
        <div className="section">
          <div className="title">
            <Typography variant="bold/32-40" as="h1">
              {level}
            </Typography>
            <Typography variant="regular/16-28">
              {anotherCourseDesc[level]}
            </Typography>
          </div>
          <div id="course-landing-detail-header-slot" />
        </div>
      </Main>
    );
  },
  {
    style: {
      backgroundColor: colorsV2.gray10
    }
  }
);
