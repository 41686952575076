import * as React from 'react';
import Button from '@dolstaff/shared/es/Button';
import styled from 'styled-components/macro';

/* eslint-disable react/prop-types */
import { Typography } from 'components/DesignSystemV2/Typography';
import { withHomePageSection } from 'components/withHomePageSection';
import { colorsV2 } from 'style/colors-v2';
import { LazyImage } from 'components/LazyImage';
import { Link } from 'gatsby';
import { fromScreen } from 'utils/media-query/responsive.util';
import IconsJuniorIconsBackHome from './icons/IconsBackHome';

const Container = styled.div`
  display: grid;
  gap: 32px;

  width: 100%;
  margin: auto;
  text-align: center;

  .header-image {
    width: 204px;
    height: 192px;
    margin: 0 auto;
  }
  .link-home {
    width: fit-content;
    margin: auto;
  }
  .fb-mess {
    margin-right: 6px;
  }
  .btn-action {
    border-radius: 12px;
  }
  .main-text {
    padding: 0 40px;
  }
  .sub-text {
    padding: 0 32px;
  }

  ${fromScreen(458)} {
    width: 400px;
  }
  ${fromScreen(1144)} {
    .main-text {
      padding: 0;
    }
  }
`;

const TextGroup = styled.div`
  display: grid;
  gap: 16px;
`;

const BtnWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const JuniorRegistrationSuccessBase = () => {
  return (
    <Container>
      <LazyImage
        className="header-image"
        src="https://gqefcpylonobj.vcdn.cloud/directus-upload/ed947dc4-1a91-4640-81c4-34948ea3f3d4.png"
        alt="dol-junior-registration-success"
      />
      <TextGroup>
        <Typography
          className="main-text"
          variant="bold/24-32"
          desktopVariant="bold/32-40"
          color={colorsV2.black100}
        >
          Đăng ký ghi danh sớm thành công!
        </Typography>
        <Typography
          className="sub-text"
          variant="regular/14-24"
          color={colorsV2.black60}
        >
          DOL sẽ liên hệ cho bạn ngay sau khi nhận được thông tin đăng ký này.
        </Typography>
        <Link to="/ielts-junior" className="link-home">
          <Button
            size="large"
            className="btn-action"
            type="primary"
            marginBottom="0"
          >
            <BtnWrapper>
              <Typography
                className="fb-mess"
                variant="semi-bold/14-24"
                color={colorsV2.white100}
              >
                Quay về Ielts Junior
              </Typography>
              <IconsJuniorIconsBackHome />
            </BtnWrapper>
          </Button>
        </Link>
      </TextGroup>
    </Container>
  );
};

export const JuniorRegistrationSuccess = withHomePageSection(
  JuniorRegistrationSuccessBase,
  {
    style: {
      backgroundColor: colorsV2.white100
    }
  },
  {}
);
