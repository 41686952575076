import * as React from 'react';
import styled from 'styled-components/macro';
import { CourseHeaderLayout } from './components/CourseHeaderLayout';
import { MainHeaderContent } from './components/MainHeaderContent';
import VideoInline from 'shared/VideoThumbnailInline/VideoInline';
import cl from 'classnames';
import { fromScreen } from 'utils/media-query/responsive.util';

const Main = styled.div``;

const RightSide = styled.div`
  .course-thumbnail {
    width: 100%;
    margin: auto;
  }
  video {
    display: block;
    margin: 0 auto;
    width: 424px !important;
  }
  ${fromScreen(776)} {
    &.ielts {
      video {
        width: 290px !important;
      }
    }
  }
`;
const VideoElement = styled(VideoInline)`
  video {
    border-radius: 8px;
  }
  > div {
    margin-bottom: 0;
  }
`;

export const CourseHeaderWithVideo = ({ data, isIelts }) => {
  const { tagText, title, description, list = [], videoUrl } = data || {};

  return (
    <Main>
      <CourseHeaderLayout
        className={cl({ ielts: isIelts })}
        mainContent={
          <MainHeaderContent
            tagText={tagText}
            title={title}
            description={description}
            list={list}
          />
        }
        videoContent={
          <RightSide className={cl({ ielts: isIelts })}>
            <VideoElement
              className="course-thumbnail"
              onContextMenu={e => e.preventDefault()}
              video={videoUrl}
              alt="course-video"
              controlsList="nodownload"
              isAutoPlay
              isMuted
            />
          </RightSide>
        }
      />
    </Main>
  );
};
