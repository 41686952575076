import * as React from 'react';

const IconSocialTag = props => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 26 26"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M13 0.5C6.09644 0.5 0.5 6.09644 0.5 13C0.5 19.9036 6.09644 25.5 13 25.5C19.9036 25.5 25.5 19.9036 25.5 13C25.5 6.09644 19.9036 0.5 13 0.5Z"
      fill="#F9FAFC"
    />
    <path
      d="M13 0.5C6.09644 0.5 0.5 6.09644 0.5 13C0.5 19.9036 6.09644 25.5 13 25.5C19.9036 25.5 25.5 19.9036 25.5 13C25.5 6.09644 19.9036 0.5 13 0.5Z"
      stroke="#F9FAFC"
    />
    <path
      d="M23 13.0619C23 7.50335 18.5206 3 13 3C7.47563 3 3 7.50335 3 13.0619C3 18.0829 6.65562 22.246 11.4375 23V15.9711H8.89813V13.0626H11.4375V10.8445C11.4375 8.3234 12.9281 6.93108 15.2131 6.93108C16.3075 6.93108 17.4531 7.12728 17.4531 7.12728V9.60315H16.19C14.95 9.60315 14.5625 10.3798 14.5625 11.1753V13.0619H17.3356L16.89 15.9705H14.5625V22.9994C19.3406 22.2454 23 18.0822 23 13.0613V13.0619Z"
      fill="#2B52D4"
    />
  </svg>
);

export default IconSocialTag;
