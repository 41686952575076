import * as React from 'react';

export const Quotes = props => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#clip0_3276_86315)">
      <path
        d="M1.02621 1.17322H7.26187C7.82801 1.17322 8.28748 1.63269 8.28748 2.19882V8.43449C8.28748 9.00064 7.82801 9.46009 7.26187 9.46009H4.27737C4.31634 11.0929 4.69582 12.3995 5.41374 13.382C5.97987 14.1574 6.83725 14.7994 7.9839 15.306C8.51106 15.5378 8.73874 16.1614 8.4926 16.6824L7.75416 18.2413C7.51622 18.7418 6.92753 18.9613 6.41883 18.7418C5.06093 18.1552 3.91429 17.4126 2.97896 16.5101C1.83846 15.4086 1.05698 14.1656 0.634427 12.7789C0.211858 11.3923 0.000603676 9.50111 0.000603676 7.1012V2.19882C0.000603676 1.63271 0.460094 1.17322 1.02621 1.17322Z"
        fill="#D3D4D7"
      />
      <path
        d="M17.825 18.7377C16.4835 18.1531 15.343 17.4106 14.4015 16.5101C13.2508 15.4086 12.4652 14.1697 12.0426 12.7933C11.6201 11.4169 11.4088 9.51958 11.4088 7.1012V2.19882C11.4088 1.63269 11.8683 1.17322 12.4344 1.17322H18.6701C19.2362 1.17322 19.6957 1.63269 19.6957 2.19882V8.43449C19.6957 9.00064 19.2362 9.46009 18.6701 9.46009H15.6856C15.7245 11.0929 16.104 12.3995 16.8219 13.382C17.3881 14.1574 18.2455 14.7994 19.3921 15.306C19.9193 15.5378 20.1469 16.1614 19.9008 16.6824L19.1644 18.2372C18.9265 18.7377 18.3337 18.9592 17.825 18.7377Z"
        fill="#D3D4D7"
      />
    </g>
    <defs>
      <clipPath id="clip0_3276_86315">
        <rect
          width="20"
          height="20"
          fill="white"
          transform="matrix(-1 0 0 1 20 0)"
        />
      </clipPath>
    </defs>
  </svg>
);
