import * as React from 'react';
import MainLayout from 'components/Layout/MainLayout';
import HeaderSchedulePage from 'shared/Header/HeaderSchedulePage';
import MySEO from 'shared/MySEO';
import { fromScreen } from 'utils/media-query/responsive.util';
import styled from 'styled-components/macro';
import { JuniorRegistrationSuccess } from 'shared/IeltsJunior/JuniorRegistrationSuccess';

const ThankYouJuniorRegistration = ({ location }) => {
  return (
    <MainLayout
      noHeader
      hideSideCTA
      renderCustomHeader={() => <HeaderSchedulePage />}
      showFooterRegister
    >
      <MySEO
        title="Đăng ký ghi danh sớm thành công!"
        slug={location.pathname}
        seoTitle="Đăng ký ghi danh sớm thành công!"
        seoDesc="Đăng ký ghi danh sớm thành công!"
        description="Đăng ký ghi danh sớm thành công!"
        featureImg="1le7VvNTqqwYfQNVbwTo"
      />
      <Space>
        <JuniorRegistrationSuccess />
      </Space>
    </MainLayout>
  );
};

const Space = styled.div`
  > section {
    padding: 64px 0 !important;
    ${fromScreen(776)} {
      padding: 100px 0 !important;
    }
  }
`;

export default React.memo(ThankYouJuniorRegistration);
