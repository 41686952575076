export const PLATFORMS = {
  TIKTOK: 'tiktok',
  FACEBOOK: 'facebook'
};

export const EVENTS = {
  CONTACT: 'Contact',
  VIEW_CONTENT: 'ViewContent',
  PageView: 'Pageview',
  CompleteRegistration: 'CompleteRegistration'
};
