import * as React from 'react';

/* eslint-disable react/prop-types */
import { withHomePageSection } from 'components/withHomePageSection';
import { colorsV2 } from 'style/colors-v2';
import { graphql, useStaticQuery } from 'gatsby';
import { StudentExperience } from 'shared/CourseLandingV3/StudentExperience';

const IeltsStudentExperienceBase = ({ data }) => {
  return <StudentExperience data={data} />;
};

export const IeltsStudentExperience = withHomePageSection(
  () => {
    const data = useStaticQuery(graphql`
      {
        gcms {
          jsonContanier(where: { name: "SmartLearning_StudentExperience" }) {
            content
          }
        }
      }
    `);
    return (
      <IeltsStudentExperienceBase data={data.gcms.jsonContanier.content.info} />
    );
  },
  {
    style: {
      backgroundColor: colorsV2.white100
    }
  },
  {}
);
