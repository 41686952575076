import * as React from 'react';
import { colorsV2 } from 'style/colors-v2';
import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  align-items: center;

  > *:not(:last-child) {
    margin-right: 8px;
  }

  input {
    text-overflow: ellipsis;
    height: auto;
    flex: 1;
    background-color: transparent;
    border: none;
    outline: none;
    padding: 0;

    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: ${colorsV2.neutral180};

    &::placeholder {
      color: ${colorsV2.neutral120};
    }

    &:disabled {
      color: ${colorsV2.black80};
    }
  }
`;

export const InputContentGeneral = React.forwardRef((props, ref) => {
  const {
    addOn,
    iconLeft,
    iconRight,
    value,
    placeholder,
    onChange,
    disabled,
    inputType = 'text',
    ...rest
  } = props;

  if (addOn) {
    return (
      <Container>
        {iconLeft}
        <input
          ref={ref}
          type={inputType}
          value={value}
          placeholder={placeholder}
          onChange={e => onChange?.(e.target.value)}
          {...rest}
        />
        {iconRight}
      </Container>
    );
  }

  return (
    <Container>
      <input
        ref={ref}
        type={inputType}
        value={value}
        placeholder={placeholder}
        onChange={e => onChange?.(e.target.value)}
        disabled={disabled}
        {...rest}
      />
    </Container>
  );
});
