import * as React from 'react';

import cl from 'classnames';
import { motion } from 'framer-motion';
import styled from 'styled-components';

import IconChevronUp from './icons/IconChevronUp';
import { colorsV2 } from 'style/colors-v2';
import { IconsPlus, IconsMinus } from 'components/Icon/SVGIcons';

/* eslint-disable react/prop-types */
const Main = styled.div`
  background: #f4f4f6;
  border-radius: 8px;
  .panel-header {
    display: flex;
    cursor: pointer;
    padding: 16px 24px;

    .panel-header-title {
      flex: 1;
      font-size: 14px;
      font-weight: 600;
      line-height: 20px;
      letter-spacing: 0px;
      margin: 0;
    }
    .panel-header-icon {
      font-size: 24px;
      line-height: 0;
      height: 24px;
    }
  }
  .panel-content {
    padding: 0px 24px;

    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0px;
  }
  &.expanded {
    background: #ffffff;
    border: 1px solid #8f92a1;
    .panel-content {
      padding: 0px 24px 16px 24px;
    }
  }
`;

const ExpandCollapseVariants = {
  expanded: { opacity: 1, height: 'auto' },
  collapsed: { opacity: 0, height: 0, overflow: 'hidden' }
};

const RotateChevronVariants = {
  expanded: { rotate: 0 },
  collapsed: { rotate: 180 }
};

const ExpandCollapsePanel = ({
  expanded = false,
  onExpandCollapseClick,
  title,
  titleProps,
  children,
  iconLeft,
  isPlusIcon,
  ...rest
}) => {
  return (
    <Main className={cl('expand-collapse-panel', { expanded: expanded })}>
      <div onClick={onExpandCollapseClick} className="panel-header">
        {iconLeft &&
          React.cloneElement(iconLeft, {
            style: {
              color: colorsV2.yellow100,
              width: 24,
              height: 24,
              marginRight: 12
            }
          })}
        <h3 className="panel-header-title" {...titleProps}>
          {title}
        </h3>
        {isPlusIcon ? (
          <motion.div className="panel-header-icon">
            {expanded ? (
              <IconsMinus color={colorsV2.neutral80} />
            ) : (
              <IconsPlus color={colorsV2.neutral80} />
            )}
          </motion.div>
        ) : (
          <motion.div
            initial={'collapsed'}
            animate={expanded ? 'expanded' : 'collapsed'}
            variants={RotateChevronVariants}
            className="panel-header-icon"
          >
            <IconChevronUp />
          </motion.div>
        )}
      </div>

      <motion.div
        className="panel-content"
        initial={'collapsed'}
        variants={ExpandCollapseVariants}
        animate={expanded ? 'expanded' : 'collapsed'}
        transition={{ duration: 0.4, ease: [0.04, 0.62, 0.23, 0.98] }}
        {...rest}
      >
        {children}
      </motion.div>
    </Main>
  );
};

export default ExpandCollapsePanel;
