/* eslint-disable react/prop-types */
import * as React from 'react';
import styled from 'styled-components';
import { withHomePageSection } from 'components/withHomePageSection';
import { fromScreen } from 'utils/media-query/responsive.util';
import { colorsV2 } from 'style/colors-v2';
import { Typography } from 'components/DesignSystemV2/Typography';
import { CourseFAQ } from 'shared/CourseLandingV3/CourseFAQ';
import { graphql, useStaticQuery } from 'gatsby';

const HeadingContainer = styled.div`
  display: grid;
  gap: 16px;
  text-align: center;
  .title {
    margin-bottom: 16px;
  }
  .landing-title {
    margin-bottom: 24px;
  }

  ${fromScreen(776)} {
    .title {
      font-size: 24px;
      line-height: 32px;
      margin-bottom: 24px;
    }
    .landing-title {
      margin-bottom: 40px;
    }
  }

  ${fromScreen(1144)} {
    .landing-title {
      text-align: center;
    }
  }
`;

const Main = styled.div``;

const staticQueryFaqs = graphql`
  query {
    allFaqs: allGoogleSpreadsheetDolLandingPageFaQs(
      filter: { status: { eq: "on" } }
    ) {
      group(field: groupClass) {
        fieldValue
        nodes {
          groupClass
          question
          content
        }
      }
    }
  }
`;

const CourseFaqsByClassGroupBase = props => {
  const queryData = useStaticQuery(staticQueryFaqs);
  const data = queryData.allFaqs.group.find(
    x => x.fieldValue === props?.classGroup
  );

  const landingTitle = {
    variant: 'bold/24-32',
    tabletVariant: 'bold/32-40',
    desktopVariant: 'bold/40-48',
    className: 'landing-title'
  };

  return (
    <Main {...props}>
      <HeadingContainer>
        <Typography variant="medium/16-20" color={colorsV2.neutral120} v3>
          FAQs
        </Typography>
        <Typography
          {...landingTitle}
          v3
          as="h2"
        >{`Thắc mắc thường gặp`}</Typography>
      </HeadingContainer>
      <CourseFAQ faqs={data.nodes} />
    </Main>
  );
};

const Container740 = styled.div`
  ${fromScreen(776)} {
    padding-bottom: 32px;
  }
  ${fromScreen(1144)} {
    position: relative;
  }
`;

export const CourseFaqsByClassGroup = withHomePageSection(
  ({ classGroup }) => (
    <Container740 id="faq-section">
      <CourseFaqsByClassGroupBase classGroup={classGroup} />
    </Container740>
  ),
  { selfish: true }
);
