import * as React from 'react';
import styled from 'styled-components';
import cl from 'classnames';

const Container = styled.div`
  display: grid;
  grid-template-columns: minmax(0, 1fr);
  row-gap: 8px;
`;

export const InputGroupGreyStyleLayout = props => {
  const {
    className,
    labelContent,
    inputContent,
    captionContent,
    error = false,
    showCaption = false
  } = props;

  return (
    <Container className={cl('input-group-grey-style-layout', className)}>
      {labelContent}
      {inputContent}
      {(showCaption || error) && captionContent}
    </Container>
  );
};
