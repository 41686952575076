export const useBreadcrumbSchema = ({ slug, heading }) => {
  const breadcrumbList = {
    '@context': 'https://schema.org',
    '@type': 'BreadcrumbList',
    '@id': `${process.env.SITE_URL}/${slug}#breadcrumb`,
    itemListElement: [
      {
        '@type': 'ListItem',
        position: 1,
        item: {
          '@type': 'WebPage',
          '@id': process.env.SITE_URL,
          url: process.env.SITE_URL,
          name: 'Trang chủ'
        }
      },
      {
        '@type': 'ListItem',
        position: 2,
        item: {
          '@id': `${process.env.SITE_URL}/khoa-hoc-ielts`,
          url: `${process.env.SITE_URL}/khoa-hoc-ielts`,
          name: 'Khoá học IELTS'
        }
      },
      {
        '@type': 'ListItem',
        position: 3,
        item: {
          '@id': `${process.env.SITE_URL}/khoa-hoc-khac`,
          url: `${process.env.SITE_URL}/khoa-hoc-khac`,
          name: 'Khoá học khác'
        }
      },
      {
        '@type': 'ListItem',
        position: 4,
        item: {
          '@id': `${process.env.SITE_URL}/${slug}`,
          url: `${process.env.SITE_URL}/${slug}`,
          name: heading || slug.toUpperCase()
        }
      }
    ]
  };

  return breadcrumbList;
};
