import * as React from 'react';
import MainLayout from 'components/Layout/MainLayout';
import HeaderSchedulePage from 'shared/Header/HeaderSchedulePage';
import MySEO from 'shared/MySEO';
import { fromScreen } from 'utils/media-query/responsive.util';
import styled from 'styled-components/macro';
import { Courses } from 'shared/CourseLandingV3/Courses';
import { IeltsStudentExperience } from 'shared/IeltsLanding/Ielts/IeltsStudentExperience';
import { IeltsCapTocHeader } from 'shared/IeltsLanding/IeltsCapToc/IeltsCapTocHeader';
import { IeltsCapTocWhatYourProblem } from 'shared/IeltsLanding/IeltsCapToc/IeltsCapTocWhatYourProblem';
import { IeltsCapTocDolSolutionSection } from 'shared/IeltsLanding/IeltsCapToc/IeltsCapTocDolSolutionSection';
import { CourseFaqsByClassGroup } from 'shared/CourseLandingV3/CourseFaqsByClassGroup';

const IeltsCapToc = ({ location }) => {
  return (
    <MainLayout
      noHeader
      hideSideCTA
      renderCustomHeader={() => <HeaderSchedulePage />}
      showFooterRegister
    >
      <MySEO
        title="IELTS cấp tốc"
        slug={location.pathname}
        seoTitle="IELTS cấp tốc"
        seoDesc="Với chương trình học tinh gọn, phương pháp Linearthinking tư duy thông minh, sự tận tâm theo sát của giáo viên và chính sách đảm bảo đầu ra, bạn sẽ tăng dc 1.5 band IELTS sau 2 tháng kèm xây được nền Tiếng Anh & tư duy ngôn ngữ vững chắc."
        description="Với chương trình học tinh gọn, phương pháp Linearthinking tư duy thông minh, sự tận tâm theo sát của giáo viên và chính sách đảm bảo đầu ra, bạn sẽ tăng dc 1.5 band IELTS sau 2 tháng kèm xây được nền Tiếng Anh & tư duy ngôn ngữ vững chắc."
        featureImg="1le7VvNTqqwYfQNVbwTo"
      />
      <Space>
        <IeltsCapTocHeader />
        <IeltsCapTocWhatYourProblem />
        <IeltsCapTocDolSolutionSection />
        <Courses
          level="Khóa IELTS cấp tốc"
          headerTitle="Các khóa học IELTS cấp tốc"
        />
        <IeltsStudentExperience />
        <CourseFaqsByClassGroup classGroup="IELTS" />
      </Space>
    </MainLayout>
  );
};

const Space = styled.div`
  overflow: hidden;
  > section {
    padding: 64px 0 !important;
    ${fromScreen(776)} {
      padding: 100px 0 !important;
    }
  }
  .section-courses {
    padding: 0 0 0 16px !important;
  }
  ${fromScreen(776)} {
    .section-courses {
      padding: 0px !important;
    }
  }
`;

export default React.memo(IeltsCapToc);
