import * as React from 'react';
import styled from 'styled-components/macro';
import { LazyImage } from 'components/LazyImage';
import { Typography } from 'components/DesignSystemV2/Typography';
import { colorsV2 } from 'style/colors-v2';
import { flexGap } from 'style/css-style';
import { TagLabel } from 'shared/TagLabel/TagLabel';
import { fromScreen } from 'utils/media-query/responsive.util';
import cl from 'classnames';
import { CourseTextLink } from './components/CourseTextLink';
import { Link } from 'gatsby';

const TagWrapper = styled.div`
  display: flex;
  align-items: center;
  ${flexGap(8)};
`;

const TimeBox = styled.div`
  position: absolute;
  top: 96.5%;
  .line {
    width: 36px;
  }
  &.left-line {
    right: 98.8%;
  }
  &.right-line {
    left: 98.8%;
  }
  .final {
    position: absolute;
    width: 74px;
    right: 10px;
    top: 50px;
  }
`;

// const Time = styled.div`
//   position: absolute;
//   top: 33%;
//   width: 73px;
//   height: 36px;
//   border-radius: 40px;
//   padding: 4px;
//   background: ${colorsV2.primary100};
// `;

const Main = styled.div`
  width: fit-content;
  background: ${colorsV2.white100};
  margin: auto;
  border: 1px solid ${colorsV2.neutral20};
  border-radius: 16px;
  padding: 8px;
  box-shadow: 0px 12px 24px 0px rgba(48, 66, 120, 0.08),
    0px 3px 6px 0px rgba(48, 66, 120, 0.06),
    0px 0px 1px 0px rgba(48, 66, 120, 0.06);
  &:nth-child(1) {
    transform: rotate(-1.73deg);
    z-index: 3;
    ${TimeBox} {
      transform: rotate(1.73deg);
    }
  }
  &:nth-child(2) {
    transform: rotate(2.09deg);
    z-index: 2;
    ${TimeBox} {
      transform: rotate(-2.09deg);
    }
  }
  &:nth-child(3) {
    transform: rotate(-1.45deg);
    z-index: 1;
    ${TimeBox} {
      transform: rotate(1.45deg);
    }
  }
`;
const BorderWrapper = styled.div`
  position: relative;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: minmax(290px, max-content) max-content max-content;
  gap: 20px;
  padding: 16px;
  background-color: transparent;
  background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='12' ry='12' stroke='%23D2D8DFFF' stroke-width='1' stroke-dasharray='12' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e");
  border-radius: 12px;
  &.single-des-content {
    grid-template-rows: minmax(244px, max-content) max-content max-content;
  }
  ${fromScreen(776)} {
    grid-template-columns: minmax(0, 260px) max-content minmax(0, 364px);
    grid-template-rows: unset;
  }
`;
const LeftSide = styled.div`
  position: relative;
  display: grid;
  grid-auto-rows: max-content;
  gap: 12px;
  .tag-header {
    width: fit-content;
    border-radius: 38px;
  }
  .image-path {
    position: absolute;
    width: 80px;
    bottom: 0;
  }
`;
const RightSide = styled.div`
  display: grid;
  gap: 20px;
  margin: 16px 0;
`;
const MainContent = styled.div`
  display: grid;
  gap: 4px;
`;
const ForWhom = styled.div``;

const ContentWrapper = styled.div`
  display: grid;
  grid-template-columns: max-content auto;
  gap: 8px;
  &.single-content {
    grid-template-columns: 1fr;
  }
  .high-light {
    font-weight: 700;
  }
`;

const CourseSuggestions = styled.div`
  display: flex;
  flex-wrap: wrap;
  ${flexGap([12, 12])};
`;

// const TimeBorder = styled.div`
//   padding: 4px 8px;
//   background-color: transparent;
//   border-radius: 16px;
//   background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='16' ry='16' stroke='white' stroke-width='1' stroke-dasharray='4' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e");
// `;

const Dot = styled.div`
  width: 5px;
  height: 5px;
  border-radius: 50%;
  background: ${colorsV2.black80};
  margin-top: 10px;
`;

const DotRed = styled.div`
  width: 5px;
  height: 5px;
  border-radius: 50%;
  background: ${colorsV2.primary60};
`;

const Divider = styled.div`
  width: 100%;
  height: 1px;
  background: ${colorsV2.neutral20};
  ${fromScreen(776)} {
    width: 1px;
    height: 100%;
  }
`;
export const LearningPath = ({
  tagText,
  title,
  descriptions,
  iconImgUrl,
  iconImgAlt,
  mainContent,
  forWhom,
  courseSuggestions,
  duration,
  classLine,
  isEnd
}) => {
  return (
    <Main>
      <BorderWrapper
        className={cl({ 'single-des-content': descriptions?.length === 1 })}
      >
        <LeftSide>
          <TagWrapper>
            <TagLabel className="tag-header" text={tagText} color="primary" />
            <DotRed />
            <Typography variant="medium/12-20" color={colorsV2.primary100}>
              {duration}
            </Typography>
          </TagWrapper>
          <Typography variant="semi-bold/20-28" color={colorsV2.black100}>
            {title}
          </Typography>
          <MainContent>
            {descriptions?.map((description, idx) => (
              <ContentWrapper
                className={cl({ 'single-content': descriptions?.length === 1 })}
                key={idx}
              >
                {descriptions?.length > 1 && <Dot />}
                <Typography variant="medium/16-24" color={colorsV2.neutral180}>
                  {description}
                </Typography>
              </ContentWrapper>
            ))}
          </MainContent>
          <LazyImage className="image-path" src={iconImgUrl} alt={iconImgAlt} />
        </LeftSide>
        <Divider />
        <RightSide>
          <MainContent>
            <Typography variant="semi-bold/16-24" color={colorsV2.neutral200}>
              Nội dung chính
            </Typography>
            {mainContent?.map((eachContent, idx) => (
              <ContentWrapper key={idx}>
                <Dot />
                <Typography
                  variant="regular/16-24"
                  color={colorsV2.black80}
                  dangerouslySetInnerHTML={{
                    __html: eachContent
                  }}
                />
              </ContentWrapper>
            ))}
          </MainContent>
          <ForWhom>
            <Typography variant="semi-bold/16-24" color={colorsV2.neutral200}>
              Khóa học dành cho ai?
            </Typography>
            {forWhom?.map((eachItem, idx) => (
              <ContentWrapper key={idx}>
                <Dot />
                <Typography variant="regular/16-24" color={colorsV2.black80}>
                  {eachItem}
                </Typography>
              </ContentWrapper>
            ))}
          </ForWhom>
          <CourseSuggestions>
            {courseSuggestions?.map((eachCourse, idx) => (
              <Link key={idx} to={eachCourse?.linkLevel}>
                <CourseTextLink
                  text={eachCourse?.text}
                  variant="regular/16-24"
                />
              </Link>
            ))}
          </CourseSuggestions>
          <TimeBox className={classLine}>
            {/* <LazyImage className="line" src={lineImg} alt="line" />
            <Time>
              <TimeBorder>
                <Typography variant="medium/16-20" color={colorsV2.white100}>
                  {duration}
                </Typography>
              </TimeBorder>
            </Time> */}
            {isEnd && (
              <LazyImage
                className="final"
                src="https://gqefcpylonobj.vcdn.cloud/directus-upload/ee5de79b-c374-4ccb-9cf7-e03e8247e415.png"
                alt="final"
              />
            )}
          </TimeBox>
        </RightSide>
      </BorderWrapper>
    </Main>
  );
};
