import * as React from 'react';
import styled from 'styled-components';
import { Typography } from 'components/DesignSystemV2/Typography';
import { IconCourseCheck } from 'components/Icon/SVGIcons';

const Icon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const FillWidth = styled.div`
  flex: 1;
`;

const Container = styled.div`
  display: flex;
  align-items: center;
  user-select: none;

  .select-dropdown-item {
    text-align: left;

    &:not(:last-child) {
      margin-right: 10px;
    }
  }
`;

export const SelectDropdownItemContent = props => {
  const { children } = props;

  return <Container>{children}</Container>;
};

const DropdownText = ({ text, color = 'black100' }) => (
  <FillWidth className="select-dropdown-item text">
    <Typography variant="medium/14-20" color={color}>
      {text}
    </Typography>
  </FillWidth>
);

const DropdownSubText = ({ text }) => (
  <Typography
    className="select-dropdown-item sub-text"
    variant="medium/14-20"
    color="black60"
  >
    {text}
  </Typography>
);

const DropdownCheck = ({ checkIcon }) => (
  <Icon className="select-dropdown-item check">
    {checkIcon || <IconCourseCheck size={18} color="black60" />}
  </Icon>
);

const DropdownIcon = ({ icon }) => (
  <Icon className="select-dropdown-item icon">{icon}</Icon>
);

const DropdownRadio = () => (
  <div className="select-dropdown-item radio">Need implement</div>
);

const DropdownCheckbox = () => (
  <div className="select-dropdown-item checkbox">Need implement</div>
);

const DropdownLetter = ({ letter }) => (
  <Typography
    className="select-dropdown-item letter"
    variant="medium/14-20"
    color="black100"
  >
    {letter}.
  </Typography>
);

SelectDropdownItemContent.FillWidth = FillWidth;
SelectDropdownItemContent.Text = DropdownText;
SelectDropdownItemContent.SubText = DropdownSubText;
SelectDropdownItemContent.Check = DropdownCheck;
SelectDropdownItemContent.Icon = DropdownIcon;
SelectDropdownItemContent.Radio = DropdownRadio;
SelectDropdownItemContent.Checkbox = DropdownCheckbox;
SelectDropdownItemContent.Letter = DropdownLetter;
