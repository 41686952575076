import * as React from 'react';
import styled from 'styled-components/macro';
import { v4 as uuidv4 } from 'uuid';

/* eslint-disable react/prop-types */
import { Typography } from 'components/DesignSystemV2/Typography';
import { withHomePageSection } from 'components/withHomePageSection';
import { colorsV2 } from 'style/colors-v2';
import { fromScreen } from 'utils/media-query/responsive.util';
import { graphql, useStaticQuery } from 'gatsby';
import Button from '@dolstaff/shared/es/Button';
import { UserPixelTracking } from 'utils/userPixelTracking';
import { useModalController } from './hooks/useModalHook';
import RegisterForm from './RegisterForm';

const Container = styled.div`
  padding: 64px 0;
  ${fromScreen(776)} {
    padding: 100px 0;
  }
`;
const Header = styled.div`
  display: grid;
  text-align: center;
  gap: 16px;
`;

const BtnWrapper = styled.div`
  display: grid;
  justify-content: center;
`;

const Content = styled.div`
  .check {
    border-radius: 12px;
  }
`;

const RoadmapImageDesktop = styled.div`
  width: 100%;
  height: 100%;
  display: none;
  margin: 64px 0;
  ${fromScreen(776)} {
    display: block;
  }

  img {
    width: 100%;
    height: 100%;
  }
`;
const RoadmapImageMobile = styled.div`
  width: 100%;
  height: 100%;
  display: block;
  margin: 64px 0;

  ${fromScreen(776)} {
    display: none;
  }

  img {
    width: 100%;
    height: 100%;
  }
`;

const IeltsJuniorRoadmapBase = ({ data }) => {
  const { isOpen, openModal, closeModal } = useModalController();

  const { roadmapSrcDesktop, roadmapSrcMobile, formLink } = data || {};

  const onClick = e => {
    const uid = uuidv4();
    UserPixelTracking.clickEventPixelSDK(uid);
    UserPixelTracking.clickEvent(uid);
    openModal();
  };

  return (
    <Container>
      <Header>
        <Typography variant="medium/16-24" color={colorsV2.neutral120} v3>
          Lựa chọn lộ trình phù hợp với con
        </Typography>
        <Typography
          variant="bold/24-32"
          tabletVariant="bold/32-40"
          desktopVariant="bold/40-48"
          color={colorsV2.black100}
          v3
        >
          Lộ trình học IELTS lý tưởng
        </Typography>
      </Header>
      <Content>
        <RoadmapImageDesktop>
          <img
            className="ielts-junior-roadmap-desktop"
            src={roadmapSrcDesktop}
            alt="ielts-junior-roadmap-desktop"
          />
        </RoadmapImageDesktop>
        <RoadmapImageMobile>
          <img
            className="ielts-junior-roadmap-mobile"
            src={roadmapSrcMobile}
            alt="ielts-junior-roadmap-mobile"
          />
        </RoadmapImageMobile>
        <BtnWrapper>
          <Button
            size="large"
            className="btn-action check"
            type="primary"
            marginBottom="0"
            onClick={onClick}
          >
            <Typography variant="semi-bold/16-24" color={colorsV2.white100}>
              Đăng ký sớm giữ chỗ
            </Typography>
          </Button>
        </BtnWrapper>
      </Content>
      <RegisterForm closeModal={closeModal} isOpen={isOpen} />
    </Container>
  );
};

export const IeltsJuniorRoadmap = withHomePageSection(
  () => {
    const data = useStaticQuery(graphql`
      {
        gcms {
          jsonContanier(where: { name: "Ielts_Junior_Roadmap" }) {
            content
          }
        }
      }
    `);

    return <IeltsJuniorRoadmapBase data={data.gcms.jsonContanier.content} />;
  },
  {
    style: {
      backgroundColor: colorsV2.paper
    }
  },
  {}
);
