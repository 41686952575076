import * as React from 'react';

function IconCourseCheck(props) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fill="currentColor"
        d="M8.557 16.505l-3.728-3.587a1.1 1.1 0 00-1.515 0 1.002 1.002 0 000 1.458l4.49 4.32a1.1 1.1 0 001.516 0l10.195-9.739a1.002 1.002 0 000-1.457A1.1 1.1 0 0018 7.5l-9.443 9.005z"
      />
    </svg>
  );
}

export default React.memo(IconCourseCheck);
