import * as React from 'react';
import MainLayout from 'components/Layout/MainLayout';
import HeaderSchedulePage from 'shared/Header/HeaderSchedulePage';
import MySEO from 'shared/MySEO';
import { fromScreen } from 'utils/media-query/responsive.util';
import styled from 'styled-components/macro';
import { ToeicAndPronunciationHeader } from 'shared/ToeicAndPronunciation/ToeicAndPronunciationHeader';
import { ToeicAndPronunciationLearningPath } from 'shared/ToeicAndPronunciation/ToeicAndPronunciationLearningPath';
import { ToeicAndPronunciationStudentExperience } from 'shared/ToeicAndPronunciation/ToeicAndPronunciationStudentExperience';
import { Courses } from 'shared/CourseLandingV3/Courses';
import { CourseFaqsByClassGroup } from 'shared/CourseLandingV3/CourseFaqsByClassGroup';

const ToeicAndPronunciation = ({ location }) => {
  return (
    <MainLayout
      noHeader
      hideSideCTA
      renderCustomHeader={() => <HeaderSchedulePage />}
      showFooterRegister
    >
      <MySEO
        title="Khóa học TOEIC & Phát âm"
        slug={location.pathname}
        seoTitle="Khóa học TOEIC & Phát âm"
        seoDesc="Phương pháp học độc quyền cùng công nghệ cá nhân hóa, giúp bạn đạt mục tiêu nhanh chóng."
        description="Phương pháp học độc quyền cùng công nghệ cá nhân hóa, giúp bạn đạt mục tiêu nhanh chóng."
        featureImg="1le7VvNTqqwYfQNVbwTo"
      />
      <Space>
        <ToeicAndPronunciationHeader />
        <Courses
          level="Khóa TOEIC và phát âm"
          headerTitle="Các khóa học TOEIC và phát âm"
        />
        <ToeicAndPronunciationLearningPath />
        <ToeicAndPronunciationStudentExperience />
        <CourseFaqsByClassGroup classGroup="TOEIC" />
      </Space>
    </MainLayout>
  );
};

const Space = styled.div`
  overflow: hidden;
  > section {
    padding: 64px 0 !important;
    ${fromScreen(776)} {
      padding: 100px 0 !important;
    }
  }
  .section-courses {
    padding: 0 0 0 16px !important;
  }
  ${fromScreen(776)} {
    .section-courses {
      padding: 0px !important;
    }
  }
`;

export default React.memo(ToeicAndPronunciation);
