import * as React from 'react';

import { flatMap } from 'lodash';
import { css } from 'styled-components';
import styled from 'styled-components/macro';

/* eslint-disable react/prop-types */
import { withHomePageSection } from 'components/withHomePageSection';
import {
  getUniqueTeachers,
  useAllTeacherData
} from 'shared/DolTeachers/AllDolTeachers';

import { Typography } from '../../../components/DesignSystemV2/Typography';
import { colorsV2 } from '../../../style/colors-v2';
import getAssetLink from '../../../utils/getAssetLink';
import IconPlayVideo from '../../DolTeachers/icons/IconPlayVideo';
import { VideoPlayer } from '../../Linearthinking/YoutubeVideoCourses';
import Modal from '../Modal';
import { fromScreen } from '../../../utils/media-query/responsive.util';
import TruncateMarkup from 'react-truncate-markup';
import { Quotes } from './icons/Quotes';

const HeadingContainer = styled.div`
  gap: 16px;
  align-items: baseline;
  margin-bottom: 24px;
`;

const Main = styled.div`
  .view-full-schedule-btn {
    background-color: ${colorsV2.white100};
    border: 2px solid ${colorsV2.primary100};
    box-sizing: border-box;
    border-radius: 8px;
  }
  ${fromScreen(776)} {
    .section-title {
      font-size: 24px;
      line-height: 32px;
    }
  }
`;

const TeacherHighlightItem = styled.div`
  display: grid;
  grid-auto-flow: column;
  grid-template-columns: 24px 1fr;
  grid-column-gap: 8px;
  align-items: center;
`;
const TeacherHighlightList = styled.div`
  padding: 16px;

  display: grid;
  grid-template-columns: auto;
  grid-column-gap: 24px;
  grid-row-gap: 16px;

  > div > div {
    display: flex;
    flex-wrap: wrap;
    gap: 4px;
  }

  ${fromScreen(776)} {
    grid-template-columns: repeat(2, 1fr);
  }
`;

const TeacherInfoWrapper = styled.div`
  border: 1px solid ${colorsV2.gray20};
  border-radius: 16px;
`;

const QuotesWrapper = styled.div`
  display: grid;
  grid-template-columns: max-content minmax(0, max-content);
  gap: 12px;

  padding: 16px;
  border-top: 1px solid ${colorsV2.gray20};
`;

const TeacherHighlights = ({ highlights, quote }) => {
  return (
    <TeacherInfoWrapper>
      <TeacherHighlightList className="teacher-highlights">
        {highlights.map((highlight, index) => {
          return (
            <TeacherHighlightItem key={index}>
              <img
                src={getAssetLink({ item: { handle: highlight.iconHandler } })}
                alt="hightlight-giao-vien"
              />
              <div>
                <Typography
                  variant="semi-bold/14-20"
                  color={highlight.id === 'linear-badge' && colorsV2.primary100}
                >
                  {highlight.title}
                </Typography>
                <Typography
                  style={{ color: colorsV2.black80 }}
                  variant="regular/14-16"
                >
                  {highlight.subText}
                </Typography>
              </div>
            </TeacherHighlightItem>
          );
        })}
      </TeacherHighlightList>
      {quote && (
        <QuotesWrapper>
          <Quotes width={20} height={20} />
          <Typography variant="regular/14-20">{quote}</Typography>
        </QuotesWrapper>
      )}
    </TeacherInfoWrapper>
  );
};

const EducationPhilosophyButtonWrapper = styled.div`
  display: grid;
  border: 1px solid ${colorsV2.primary20};
  border-radius: 16px;
  cursor: pointer;
  grid-template-columns: max-content;
  grid-auto-flow: column;
  align-items: center;
  padding: 10px 16px;
  height: fit-content;
  width: fit-content;
  gap: 10px;
  margin-top: 16px;
  > .icon {
    font-size: 20px;
    line-height: 0;
    color: ${colorsV2.primary100};
  }
  > p {
    color: ${colorsV2.primary100};
  }

  ${fromScreen(458)} {
    margin-top: 0;
  }

  ${fromScreen(776)} {
    padding: 10px 40px;
  }
`;
const EducationPhilosophyButton = ({ videoIntroduction }) => {
  const [showModal, setShowModal] = React.useState(false);
  const [videoUrl, setVideoUrl] = React.useState();
  return (
    <>
      <EducationPhilosophyButtonWrapper
        onClick={e => {
          e.stopPropagation();
          setVideoUrl(videoIntroduction);
          setShowModal(true);
        }}
        className="video-teacher-featured"
      >
        <div className="icon">
          <IconPlayVideo color={colorsV2.primary100} />
        </div>
        <Typography variant="semi-bold/14-24">Triết lý giáo dục</Typography>
      </EducationPhilosophyButtonWrapper>
      <Modal isOpen={showModal} onRequestClose={() => setShowModal(false)}>
        <VideoPlayer videoUrl={videoUrl} autoPlay />
      </Modal>
    </>
  );
};

const TeacherDetailInfoWrapper = styled.div`
  display: grid;
  grid-gap: 16px;

  grid-template-areas:
    'teacher-summary'
    'teacher-highlights-description'
    'teacher-introduction';

  .teacher-summary {
    grid-area: teacher-summary;

    ${fromScreen(458)} {
      display: grid;
      grid-template-columns: minmax(0, 1fr) max-content;
      gap: 12px;
      align-items: center;
    }
  }

  .teacher-review {
    grid-area: teacher-review;
  }

  .teacher-highlights-description {
    grid-area: teacher-highlights-description;
  }

  .teacher-introduction {
    grid-area: teacher-introduction;
    .ellipsis-text {
      font-family: 'Inter', sans-serif;
      font-size: 14px;
      line-height: 20px;
      font-weight: 700;
      color: ${colorsV2.blue100};
      cursor: pointer;
    }
  }

  .content-css {
    &.content {
      font-family: 'Inter', sans-serif;
      font-size: 14px;
      line-height: 20px;
      font-weight: 400;
      color: ${colorsV2.black100};
    }
  }

  .teacher-highlights-description {
    display: grid;
  }
  .teacher-introduction {
  }

  .teacher-review {
  }

  grid-template-columns: repeat(24, [col-start] 1fr);
  .teacher-summary {
    grid-column-start: 1;
    grid-column-end: 24;
    grid-row-start: 1;
  }

  .teacher-highlights-description {
    grid-column-start: 1;
    grid-column-end: 24;
    grid-row-start: 2;
    //grid-auto-columns: max-content;
  }

  .teacher-introduction {
    grid-column-start: 1;
    grid-column-end: 24;
    grid-row-start: 3;
  }

  .teacher-review {
    grid-column-start: 19;
    grid-column-end: 25;
    grid-row-start: 2;
    grid-row-end: 4;
  }
`;

const TeacherHighlightsDescription = styled.div`
  grid-row-gap: 16px;

  ${EducationPhilosophyButtonWrapper} {
    max-width: max-content;
  }

  ${fromScreen(1144)} {
    grid-row-gap: 24px;
  }
`;

const TruncatedText = styled.div`
  font-family: 'Inter', sans-serif;
  font-size: 14px;
  line-height: 20px;
  font-weight: 400;
  color: ${colorsV2.black100};
`;

const TeacherDetailInfo = ({
  displayName,
  displayNameSubtitle,
  highlights,
  teacherDescription,
  videoIntroduction,
  quote
}) => {
  const [isReadFull, setIsReadFull] = React.useState(false);
  return (
    <TeacherDetailInfoWrapper>
      <div className="teacher-summary">
        <div>
          <Typography variant="bold/20-28" as="h3">
            {displayName}
          </Typography>
          <Typography
            style={{ color: colorsV2.black80 }}
            variant="regular/14-16"
          >
            {displayNameSubtitle}
          </Typography>
        </div>

        {videoIntroduction && (
          <EducationPhilosophyButton videoIntroduction={videoIntroduction} />
        )}
      </div>

      <TeacherHighlightsDescription className="teacher-highlights-description">
        <TeacherHighlights highlights={highlights} quote={quote} />
      </TeacherHighlightsDescription>

      <div className="teacher-introduction">
        {teacherDescription && !isReadFull && (
          <TruncateMarkup
            lines={3}
            ellipsis={
              <span>
                {' '}
                ...{' '}
                <span
                  className="ellipsis-text"
                  onClick={() => setIsReadFull(true)}
                >
                  Xem thêm
                </span>
              </span>
            }
          >
            <TruncatedText>{teacherDescription}</TruncatedText>
          </TruncateMarkup>
        )}
        {teacherDescription && isReadFull && (
          <Typography className="teacher-introduction" variant="regular/14">
            {teacherDescription.split('\n').map((item, key) => {
              return (
                <>
                  <span key={key}>{item}</span>
                  <br />
                </>
              );
            })}
          </Typography>
        )}
      </div>
    </TeacherDetailInfoWrapper>
  );
};

const ClassTeacherOfLevelContent = styled.div``;
const TeacherAvatar = styled.div`
  border-radius: 50%;
  overflow: auto;
  padding: 4px;
  cursor: pointer;
  border: 2px solid transparent;
  line-height: 0;
  img {
    opacity: 0.7;
    width: 96px;
    transition: all 0.3s;
    ${fromScreen(458)} {
      width: 115px;
    }
  }
  &:hover {
    img {
      opacity: 1;
    }
  }
  ${p =>
    p.selected &&
    css`
      border: 1px solid ${colorsV2.primary100};
      img {
        opacity: 1;
      }
    `};
`;
const TeachersThumbnailListWrapper = styled.div`
  display: grid;
  grid-auto-flow: column;
  grid-auto-columns: max-content;
  grid-column-gap: 16px;
  margin-bottom: 18px;
  overflow: auto;
`;
const TeachersThumbnailList = ({
  teachers,
  selectedTeacherIndex,
  setSelectedTeacher
}) => {
  return (
    <TeachersThumbnailListWrapper>
      {teachers.map((teacher, index) => (
        <TeacherAvatar
          key={index}
          selected={index === selectedTeacherIndex}
          onClick={() => setSelectedTeacher(index)}
        >
          <img
            src={getAssetLink({
              item: { handle: teacher.detailImageHandler },
              width: 124
            })}
            alt={teacher.fullName}
          />
        </TeacherAvatar>
      ))}
    </TeachersThumbnailListWrapper>
  );
};

export const ClassTeacherOfLevelV3 = withHomePageSection(
  ({ levelGroupName, levels }) => {
    const { getTeacherOfLevel } = useAllTeacherData();

    const teachers = getUniqueTeachers(
      flatMap(levels.map(levelName => getTeacherOfLevel(levelName)))
    );
    const [selectedItem, setSelectedItem] = React.useState(0);
    const levelTeacher = teachers[selectedItem];

    if (!levelTeacher) {
      return null;
    }

    return (
      <Main id="class-teacher-of-level">
        <HeadingContainer>
          <Typography
            variant="bold/20-28"
            desktop="bold/24-32"
            v3
            className="section-title"
            as="h2"
          >{`Giáo viên ${levelGroupName}`}</Typography>
        </HeadingContainer>
        <ClassTeacherOfLevelContent>
          <TeachersThumbnailList
            teachers={teachers}
            selectedTeacherIndex={selectedItem}
            setSelectedTeacher={setSelectedItem}
          />
          <TeacherDetailInfo
            numberOfStudent={levelTeacher.numberOfStudent}
            feedbackScore={levelTeacher.feedbackScore}
            feedbackReceive={levelTeacher.feedbackReceive}
            highlights={levelTeacher.highlights}
            displayName={levelTeacher.fullName}
            displayNameSubtitle={levelTeacher.displayNameSubtitle}
            teacherDescription={levelTeacher.teacherDescription}
            videoIntroduction={levelTeacher.videoIntroduction}
            quote={levelTeacher?.quote}
          />
        </ClassTeacherOfLevelContent>
      </Main>
    );
  },
  { selfish: true, style: { backgroundColor: colorsV2.white100 } }
);
