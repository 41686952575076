import * as React from 'react';

function IconsBackHome(props) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 20 20" fill="none" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.94194 3.30806C8.18602 3.55214 8.18602 3.94786 7.94194 4.19194L6.50888 5.625H10C13.1068 5.625 15.625 8.14316 15.625 11.25C15.625 14.3568 13.1068 16.875 10 16.875C6.89316 16.875 4.375 14.3568 4.375 11.25C4.375 10.9048 4.65482 10.625 5 10.625C5.34518 10.625 5.625 10.9048 5.625 11.25C5.625 13.6665 7.58351 15.625 10 15.625C12.4165 15.625 14.375 13.6665 14.375 11.25C14.375 8.83351 12.4165 6.875 10 6.875H6.50888L7.94194 8.30806C8.18602 8.55214 8.18602 8.94786 7.94194 9.19194C7.69786 9.43602 7.30214 9.43602 7.05806 9.19194L4.55806 6.69194C4.49861 6.6325 4.45364 6.56405 4.42315 6.491C4.39213 6.41683 4.375 6.33542 4.375 6.25M4.42275 6.00997C4.43767 5.97407 4.45607 5.93927 4.47797 5.9061C4.50085 5.87145 4.52755 5.83857 4.55806 5.80806L7.05806 3.30806C7.30214 3.06398 7.69786 3.06398 7.94194 3.30806M4.375 6.24798C4.37527 6.16367 4.39223 6.08329 4.42275 6.00997L4.375 6.24798Z"
        fill="currentColor"
      />
    </svg>
  );
}

const IconsJuniorIconsBackHome = React.memo(IconsBackHome);
export default IconsJuniorIconsBackHome;
