import * as React from 'react';

import format from 'date-fns/format';
import vi from 'date-fns/locale/vi';
import { StaticQuery, graphql, Link } from 'gatsby';
import get from 'lodash/get';
import take from 'lodash/take';
import styled from 'styled-components';
import { fromScreen } from 'utils/media-query/responsive.util';

import { LazyImage } from 'components/LazyImage';
import BubbleTitle from 'shared/SchedulePage/BubbleTitle';
import { colorsV2 } from 'style/colors-v2';
import { useDeviceDetect3 } from 'utils/hooks/useDeviceQuery.hook';
import { MasonryLayout } from '../Layout/MasonryLayout';
import { Typography } from '../../components/DesignSystemV2/Typography';
import IconQuote from './icons/IconQuote';
import IconSocialTag from './icons/IconSocialTag';
import IconArrowDown from './icons/IconArrowDown';
import { motion } from 'framer-motion';
import { IconArrowArrowRightLine } from 'components/Icon/SVGIcons';

const ImgAvatar = styled(LazyImage)`
  max-width: 48px;
  max-height: 48px;
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`;

const Main = styled.div`
  .review-title {
    margin-bottom: 24px;
  }
  .title-detail-course {
    margin-bottom: 16px;
  }
  .layout-columns {
    .column.flex {
      border-bottom: 1px solid ${colorsV2.gray20};
      &:last-child {
        border-bottom: none;
      }
    }
  }

  .see-more-review {
    margin-top: 24px;
    display: grid;
    place-items: center;
    button {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: transparent;
      border: 1px solid ${colorsV2.primary20};
      padding: 10px 0;
      border-radius: 12px;
      font-size: 14px;
      font-weight: 600;
      line-height: 24px;
      color: ${colorsV2.primary100};

      transition: 0.2s all;
      cursor: pointer;
      &:hover {
        > p {
          color: ${colorsV2.primary80};
        }
      }
      &:active {
        transform: scale(0.98);
      }
    }
  }

  ${fromScreen(776)} {
    .title-detail-course {
      margin-bottom: 24px;
      font-size: 24px;
      line-height: 32px;
    }
    .review-title {
      margin-bottom: 40px;
    }
  }

  ${fromScreen(1144)} {
    .review-title {
      text-align: center;
    }
  }
`;

const HeaderSection = styled.div`
  display: grid;
  grid-template-columns: 48px 1fr 64px;
  gap: 16px;
  .card-review-header-avatar {
    img {
      border-radius: 50%;
    }
  }
  .card-review-header-info {
    display: grid;
    align-items: center;
    margin-right: 4px;
    height: 100%;
    .date {
      color: ${colorsV2.black80};
    }
    .title {
      display: -webkit-box;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
      overflow: hidden;
    }
  }
  .card-review-fb {
    position: relative;
    .review-image {
      position: absolute;
      right: 0;
      z-index: 1;
      img {
        border: 1px solid ${colorsV2.neutral20};
        border-radius: 16px;
      }
    }

    .card-review-fb-icon {
      position: absolute;
      z-index: 2;
      top: -2px;
      right: -4px;
    }
  }
`;

const DescriptionSection = styled.div`
  position: relative;
`;
const ClamppedText = styled.span`
  display: -webkit-box;
  -webkit-line-clamp: 5;
  -webkit-box-orient: vertical;
  overflow: hidden;
`;

const QuoteIconWrapper = styled.div`
  position: absolute;
  top: -15px;
  left: -5px;
`;

const ReviewWrapper = styled.div`
  height: 48px;
  width: 289px;
  background: radial-gradient(
    100% 211.87% at 100% 100%,
    #f9fafc 47.71%,
    rgba(255, 255, 255, 0) 100%
  );

  position: absolute;
  bottom: 0;
  right: 0;

  display: none;
  justify-content: end;
  align-items: end;

  > a {
    display: grid;
    align-items: center;
    grid-template-columns: max-content max-content;
    gap: 6px;
    padding: 0 6px 0px 10px;
    cursor: pointer;
    &:hover {
      background: ${colorsV2.primary10};
      border-radius: 20px;
    }
  }
  svg {
    width: 16px;
    height: 16px;
    color: ${colorsV2.primary100};
  }
`;

const ReviewCardWrapper = styled.div`
  position: relative;
  display: grid;
  gap: 24px;

  border: 1px solid ${colorsV2.neutral20};
  border-radius: 24px;

  padding: 32px;
  background: ${colorsV2.neutral5};

  &:hover {
    border: 1px solid ${colorsV2.neutral80};
    ${QuoteIconWrapper} {
      svg {
        color: ${colorsV2.blackTransparent100};
      }
    }
    ${ReviewWrapper} {
      display: grid;
    }
  }
`;

const ReviewCard = ({
  avatarLink,
  fullName,
  dateFormatted,
  reviewContent,
  reviewLink,
  linkImage
}) => {
  return (
    <ReviewCardWrapper>
      <HeaderSection>
        <div className="card-review-header-avatar">
          <ImgAvatar
            imgProps={{
              alt: 'student-review'
            }}
            width={48}
            height={48}
            handle={avatarLink}
          />
        </div>
        <div className="card-review-header-info">
          <Typography variant="semi-bold/16-24" className="title">
            {fullName}
          </Typography>
          <Typography variant="regular/14-20" className="date">
            {dateFormatted}
          </Typography>
        </div>
        <div className="card-review-fb">
          {linkImage && (
            <motion.div
              className="review-image"
              whileHover={{ width: 295 }}
              transition={{ duration: 0.5 }}
            >
              <LazyImage
                imgProps={{
                  alt: 'student-review'
                }}
                handle={linkImage}
              />
            </motion.div>
          )}

          <IconSocialTag
            className="card-review-fb-icon"
            width={20}
            height={20}
            color={colorsV2.blue100}
          />
        </div>
      </HeaderSection>
      <DescriptionSection>
        <ClamppedText>
          <Typography variant="regular/16-28">{reviewContent}</Typography>
        </ClamppedText>
        <ReviewWrapper>
          <Link
            href={reviewLink}
            target="_blank"
            rel="noopener noreferrer nofollow"
          >
            <Typography variant="medium/16-24" color={colorsV2.primary100}>
              Xem chi tiết
            </Typography>
            <IconArrowArrowRightLine />
          </Link>
        </ReviewWrapper>
      </DescriptionSection>
      <QuoteIconWrapper>
        <IconQuote width={36} height={36} color={colorsV2.neutral40} />
      </QuoteIconWrapper>
    </ReviewCardWrapper>
  );
};

// const limitReview = 6;

export const StudentCourseReviews = ({
  data,
  bubble,
  columns = 3,
  title,
  titleDetailCourse,
  noTitle = false
}) => {
  const [page, setPage] = React.useState(0);
  const { isMobile } = useDeviceDetect3();
  let limitReview;
  if (isMobile) {
    limitReview = 3;
  } else {
    limitReview = 4;
  }
  const maxPage = Math.floor(data.length / limitReview);
  const reviewPaged = take(data, (page + 1) * limitReview);
  const remainItems = data.length - (page + 1) * limitReview;

  const sectionTitle = React.useMemo(() => {
    if (noTitle) {
      return null;
    }

    if (title) {
      return (
        <Typography
          variant="bold/24-32"
          tabletVariant="bold/32-40"
          desktopVariant="bold/40-48"
          v3
          className="review-title"
          as="h2"
        >
          {title}
        </Typography>
      );
    }

    if (titleDetailCourse) {
      return (
        <Typography
          variant="bold/20-28"
          desktopVariant="bold/24-32"
          v3
          className="title-detail-course"
          as="h2"
        >
          {titleDetailCourse}
        </Typography>
      );
    }

    return (
      <BubbleTitle
        data={
          bubble || [{ type: 'text', text: '❣ Tâm tình học viên dành cho DOL' }]
        }
      />
    );
  }, [noTitle, title, titleDetailCourse, bubble]);

  if (data.length === 0) return null;

  return (
    <Main id={'course-landing-student-reviews'}>
      {sectionTitle}

      <MasonryLayout breakpointCols={columns}>
        {reviewPaged.map(review => {
          const {
            id,
            reviewDate,
            fullName,
            reviewLink,
            avatarLink,
            coverLink,
            reviewContent
          } = review.node;
          const dateFormatted = format(new Date(reviewDate), 'MM/yyyy', {
            locale: vi
          });
          return (
            <ReviewCard
              title={sectionTitle}
              key={id}
              dateFormatted={dateFormatted}
              fullName={fullName}
              avatarLink={avatarLink}
              linkImage={coverLink}
              reviewLink={reviewLink}
              reviewContent={reviewContent}
            />
          );
        })}
      </MasonryLayout>

      {remainItems > 0 && (
        <div className="see-more-review">
          <button
            disabled={page === maxPage}
            onClick={() => setPage(prevPage => prevPage + 1)}
          >
            <Typography variant="semi-bold/14-24" color={colorsV2.primary100}>
              Xem thêm review
            </Typography>
            <IconArrowDown width={24} height={24} color={colorsV2.primary100} />
          </button>
        </div>
      )}
    </Main>
  );
};

const studentOnlineCourseFacebookReviewsQuery = graphql`
  query allGoogleSpreadsheetDolLandingPageReviewAllNewQueryV3 {
    allGoogleSpreadsheetDolLandingPageReviewAllNew(
      filter: { listing: { eq: "listed" } }
      sort: { fields: order, order: ASC }
    ) {
      edges {
        node {
          id
          reviewLink
          avatarLink
          coverLink
          fullName
          reviewDate
          reviewContent
          listing
          program
          order
        }
      }
    }
  }
`;

/**
 *
 * columns: { 775: 1, 1143: 2, default: 3 }
 *
 * 775 and 1143: max-width
 * default: largest screen
 *
 * **/

const StudentReviewsNewV3 = ({
  program,
  bubble,
  columns,
  title,
  titleDetailCourse,
  noTitle = false
}) => {
  return (
    <StaticQuery
      query={studentOnlineCourseFacebookReviewsQuery}
      render={data => {
        let reviews =
          data.allGoogleSpreadsheetDolLandingPageReviewAllNew.edges.filter(
            item => {
              let isValidClassType = item.node.program === program;
              const isNotBlank =
                (get(item, 'node.reviewContent', '') || '').trim().length > 0;
              if (program === 'all') isValidClassType = true;
              return isValidClassType && item.node.avatarLink && isNotBlank;
            }
          );
        if (reviews.length === 0) {
          reviews =
            data.allGoogleSpreadsheetDolLandingPageReviewAllNew.edges.filter(
              item => {
                const isNotBlank =
                  (get(item, 'node.reviewContent', '') || '').trim().length > 0;
                return item.node.avatarLink && isNotBlank;
              }
            );
        }

        return (
          <StudentCourseReviews
            bubble={bubble}
            data={reviews}
            columns={columns}
            title={title}
            titleDetailCourse={titleDetailCourse}
            noTitle={noTitle}
          />
        );
      }}
    />
  );
};

export default StudentReviewsNewV3;
