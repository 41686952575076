import * as React from 'react';
import { DropdownItemItemLayout } from 'shared/DropdownItemItemLayout/DropdownItemItemLayout';
import { InputContentDropdownSelect } from 'shared/InputContentDropdownSelect/InputContentDropdownSelect';
import { InputGroupGreyStyleLayout } from 'shared/InputGroupGreyStyleLayout/InputGroupGreyStyleLayout';
import { InputItemLabel } from 'shared/InputItemLabel/InputItemLabel';
import { SelectDropdownItemContent } from 'shared/SelectDropdownItemContent/SelectDropdownItemContent';
import { SelectGreyStyleInputGeneral } from 'shared/SelectGreyStyleInputGeneral/SelectGreyStyleInputGeneral';
import { SelectOptionList } from 'shared/SelectOptionList/SelectOptionList';
import { scrollBarStylesGeneral } from 'style/css-style';
import styled from 'styled-components';

const ScrollContent = styled.div`
  display: flex;
  flex-direction: column;

  max-height: 210px;
  overflow-y: auto;
  ${scrollBarStylesGeneral()}
`;

export const GoalItem = props => {
  const { value: selectedKey, onChange, options, disabled = false } = props;

  const selectedOption = React.useMemo(() => {
    return options?.find(option => option.key === selectedKey);
  }, [options, selectedKey]);

  return (
    <InputGroupGreyStyleLayout
      className="form-item-goal"
      labelContent={<InputItemLabel text="Mục tiêu IELTS" required hasColon />}
      inputContent={
        <SelectGreyStyleInputGeneral
          inputContent={
            <InputContentDropdownSelect
              text={selectedOption?.value}
              placeholder="Lựa chọn mục tiêu IELTS"
            />
          }
          dropdownContent={
            <SelectOptionList>
              <ScrollContent>
                {options?.map(({ key, value }) => (
                  <DropdownItemItemLayout
                    key={key}
                    content={
                      <SelectDropdownItemContent>
                        <SelectDropdownItemContent.Text text={value} />
                      </SelectDropdownItemContent>
                    }
                    onClick={() => onChange?.(key)}
                    selected={key === selectedKey}
                  />
                ))}
              </ScrollContent>
            </SelectOptionList>
          }
          disabled={disabled || !options?.length}
        />
      }
    />
  );
};
