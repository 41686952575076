import { groupBy, keys, map, merge, pick, reduce, values } from 'lodash';
import { graphql, useStaticQuery } from 'gatsby';
import { useAllTeacherData } from 'shared/DolTeachers/AllDolTeachers';

const ungroupData = groupedData => {
  return reduce(
    map(groupedData.group, groupItem => {
      return {
        item: groupItem.edges[0].node,
        fieldValue: groupItem.fieldValue
      };
    }),
    (merged, item) => {
      return { ...merged, [item.fieldValue]: item.item };
    },
    {}
  );
};

const calculateLearningDuration = sylabus => {
  return reduce(
    sylabus,
    (sum, item) => (sum += Number(item.durationInMinutes)),
    0
  );
};
const toLearningStatistic = syllabusByLevel => {
  const learningStatistics = map(values(syllabusByLevel), syllabuses => {
    const syllabusByType = groupBy(syllabuses, 'syllabusType');
    return {
      level: syllabuses[0].level,
      theoryDurationInMinutes: calculateLearningDuration(syllabusByType.theory),
      practiceDurationInMinutes: calculateLearningDuration(
        syllabusByType.practice
      )
    };
  });
  return reduce(
    learningStatistics,
    (merged, statistic) => {
      return { ...merged, [statistic.level]: { ...statistic } };
    },
    {}
  );
};

const listDataFromGrouped = groupedData => {
  return reduce(
    map(groupedData.group, groupItem => {
      return {
        items: groupItem.edges.map(edge => edge.node),
        fieldValue: groupItem.fieldValue
      };
    }),
    (merged, item) => {
      return { ...merged, [item.fieldValue]: item.items };
    },
    {}
  );
};

const whatYouGetKeys = Array.from(
  { length: 8 },
  (_, index) => `whatYouGet${index + 1}`
);

const mapToCourseFrom = ({ data, teachers }) => {
  const {
    allGoogleSpreadsheetDolLandingPageMarLevelData,
    allGoogleSpreadsheetDolLandingPageAcaSyllabusByLevel,
    allGoogleSpreadsheetDolLandingPageRatingByLevel
  } = data;

  const levels = ungroupData(allGoogleSpreadsheetDolLandingPageMarLevelData);
  const allLevels = keys(levels);
  const levelLearningInfo = pick(
    ungroupData(allGoogleSpreadsheetDolLandingPageAcaSyllabusByLevel),
    allLevels
  );
  const levelLearningStatistic = pick(
    toLearningStatistic(
      listDataFromGrouped(allGoogleSpreadsheetDolLandingPageAcaSyllabusByLevel)
    ),
    allLevels
  );
  const levelRating = pick(
    ungroupData(allGoogleSpreadsheetDolLandingPageRatingByLevel),
    allLevels
  );
  const levelInfo = merge(
    merge(merge(levels, levelLearningInfo), levelRating),
    levelLearningStatistic
  );

  return map(values(levelInfo), level => {
    const teacher = teachers.find(item => item.name === level.teacherRw);
    const whatYouGets = whatYouGetKeys.map(key => level[key]).filter(i => i);
    return {
      id: level.name,
      order: level.order,
      classGroupHomepageOrder: level.classGroupHomepageOrder,
      name: level.levelDisplay,
      linkLevel: level.slug,
      level: level.level,
      description: level.levelDescription,
      levelVideoThumbnailHandle: level.levelVideoThumbnailHandle,
      levelVideo: level.levelVideo,
      teacher,
      isBestSeller: level.levelBestseller === 'TRUE',
      updatedAt: level.levelUpdated,
      // Learning statistic
      numberOfLectures: level.totalSessions,
      theoryDurationInMinutes: level.theoryDurationInMinutes,
      practiceDurationInMinutes: level.practiceDurationInMinutes,
      classGroup: level.classGroup,
      // Rating
      starsInFloat: level.average ? level.average / 2 : 0,
      ratedUsers: level.count ? level.count : 0,
      // Includes
      includes: {
        linearthinking: true,
        free: 4,
        weeks: level.totalWeeks,
        sessions: level.totalSessions,
        hours: level.totalHours,
        promotion: 'Tặng app & sách giáo trình'
      },
      whatYouGets
    };
  });
};

export const useCoursesWithTeacher = () => {
  const data = useStaticQuery(graphql`
    {
      onlineLevelData: allGoogleSpreadsheetDolLandingPageMarLevelData(
        filter: { classGroup: { eq: "IELTS Online" } }
      ) {
        group(field: level) {
          edges {
            node {
              level
              levelDescription
              levelDisplay
              teacherRw
              levelBestseller
              levelUpdated
              classGroupScheduleOrder
              classGroupHomepageOrder
              order
            }
          }
          fieldValue
        }
      }
      allGoogleSpreadsheetDolLandingPageMarLevelData(
        filter: { classGroup: { ne: "IELTS Online" } }
      ) {
        group(field: level) {
          edges {
            node {
              levelVideo
              levelVideoThumbnailHandle
              level
              classGroup
              slug
              levelDescription
              levelDisplay
              teacherRw
              levelBestseller
              levelUpdated
              classGroupScheduleOrder
              classGroupHomepageOrder
              order
              whatYouGet1
              whatYouGet2
              whatYouGet3
              whatYouGet4
              whatYouGet5
              whatYouGet6
              whatYouGet7
              whatYouGet8
            }
          }
          fieldValue
        }
      }
      allGoogleSpreadsheetDolLandingPageAcaSyllabusByLevel {
        group(field: level) {
          edges {
            node {
              level
              syllabusType
              totalHours
              totalSessions
              totalWeeks
              durationInMinutes
            }
          }
          fieldValue
        }
      }

      allGoogleSpreadsheetDolLandingPageRatingByLevel {
        group(field: level) {
          edges {
            node {
              level
              count
              average
            }
          }
          fieldValue
        }
      }
      onlineCourseDefaultInfo: gcms {
        jsonContanier(where: { name: "Landing_Course_CourseList_Online" }) {
          content
        }
      }
    }
  `);

  const { teachers } = useAllTeacherData();
  const courseWithTeachers = mapToCourseFrom({
    data,
    teachers
  });

  return { courseWithTeachers };
};

const anotherCourseName = [
  { title: 'TOEIC', level: 'AIM 550+' },
  { title: 'SAT', level: 'S.InterV' },
  { title: 'Tiếng anh giao tiếp', level: 'PRONUN' }
];

export const groupCoursesIeltsForDisplay = (courses, groupCourses) => {
  const ieltsCourseOnly = groupCourses.filter(item =>
    item.name.includes('IELTS')
  );
  const anotherCourse = anotherCourseName.map(elm => elm.level).flat();
  const listCourse = [
    ...ieltsCourseOnly,
    {
      name: 'Khóa học khác',
      levels: anotherCourse
    }
  ];
  return groupCoursesForDisplay(courses, listCourse);
};

export const groupCoursesForDisplay = (courses, groupCourses) => {
  return groupCourses
    .map(courseGroupMapping => {
      const coursesOfGroup = courseGroupMapping.levels.map(courseInfo => {
        return courses.find(course => courseInfo === course.level);
      });
      return {
        name: courseGroupMapping.name,
        courses: coursesOfGroup
      };
    })
    .reduce((mergedObj, current) => {
      return {
        ...mergedObj,
        [current.name]: current.courses
      };
    }, {});
};
