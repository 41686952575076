import * as React from 'react';

/* eslint-disable react/prop-types */
import { withHomePageSection } from 'components/withHomePageSection';
import { colorsV2 } from 'style/colors-v2';
import { DolSolutionSection } from 'shared/CourseLandingV3/DolSolutionSection';
import { graphql, useStaticQuery } from 'gatsby';

const IeltsNangCaoDolSolutionSectionBase = ({ data }) => {
  return (
    <DolSolutionSection
      data={data}
      title="Với Linearthinking, DOL sẽ giúp bạn"
      subTitle="Có gì khó để DOL lo!"
    />
  );
};

export const IeltsNangCaoDolSolutionSection = withHomePageSection(
  () => {
    const data = useStaticQuery(graphql`
      {
        gcms {
          jsonContanier(
            where: { name: "IeltsLanding_IeltsNangCao_SolutionSection" }
          ) {
            content
          }
        }
      }
    `);
    return (
      <IeltsNangCaoDolSolutionSectionBase
        data={data.gcms.jsonContanier.content}
      />
    );
  },
  {
    style: {
      backgroundColor: colorsV2.white100
    }
  },
  {}
);
