import * as React from 'react';
import styled from 'styled-components/macro';
import { LazyImage } from 'components/LazyImage';
import { Typography } from 'components/DesignSystemV2/Typography';
import { colorsV2 } from 'style/colors-v2';
import { flexGap } from 'style/css-style';
import { TagLabel } from 'shared/TagLabel/TagLabel';
import cl from 'classnames';
import { CourseTextLink } from './components/CourseTextLink';
import { Link } from 'gatsby';

const TagWrapper = styled.div`
  display: flex;
  align-items: center;
  ${flexGap(8)};
`;

const Main = styled.div`
  width: fit-content;
  height: fit-content;
  background: ${colorsV2.white100};
  border: 1px solid ${colorsV2.neutral20};
  border-radius: 16px;
  padding: 8px;
  box-shadow: 0px 12px 24px 0px rgba(48, 66, 120, 0.08),
    0px 3px 6px 0px rgba(48, 66, 120, 0.06),
    0px 0px 1px 0px rgba(48, 66, 120, 0.06);
  &:nth-child(1) {
    transform: rotate(-3.72deg);
  }
  &:nth-child(2) {
    transform: rotate(0.89deg);
  }
  &:nth-child(3) {
    transform: rotate(-4.8deg);
  }
`;
const BorderWrapper = styled.div`
  position: relative;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: minmax(0px, max-content) max-content max-content;
  gap: 20px;
  padding: 16px;
  background-color: transparent;
  background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='12' ry='12' stroke='%23D2D8DFFF' stroke-width='1' stroke-dasharray='12' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e");
  border-radius: 12px;
`;
const TopSide = styled.div`
  position: relative;
  display: grid;
  grid-auto-rows: max-content;
  gap: 12px;
  padding-top: 100px;
  .tag-header {
    width: fit-content;
    border-radius: 38px;
  }
  .image-path {
    position: absolute;
    width: 80px;
    top: 0;
  }
  .check-point {
    position: absolute;
    width: 205px;
    top: -60px;
    right: -40px;
  }
`;
const BottomSide = styled.div`
  display: grid;
  gap: 20px;
  margin: 16px 0;
`;
const MainContent = styled.div`
  display: grid;
  gap: 4px;
`;
const ForWhom = styled.div``;

const ContentWrapper = styled.div`
  display: grid;
  grid-template-columns: max-content auto;
  gap: 8px;
  &.single-content {
    grid-template-columns: 1fr;
  }
  .high-light {
    font-weight: 700;
  }
`;

const CourseSuggestions = styled.div`
  display: flex;
  flex-wrap: wrap;
  ${flexGap([12, 12])};
`;

const Dot = styled.div`
  width: 5px;
  height: 5px;
  border-radius: 50%;
  background: ${colorsV2.black80};
  margin-top: 10px;
`;

const DotRed = styled.div`
  width: 5px;
  height: 5px;
  border-radius: 50%;
  background: ${colorsV2.primary60};
`;

const Divider = styled.div`
  width: 100%;
  height: 1px;
  background: ${colorsV2.neutral20};
`;
export const LearningPathOneColumn = ({
  tagText,
  title,
  descriptions,
  iconImgUrl,
  iconImgAlt,
  checkPointImg,
  checkPointAlt,
  mainContent,
  forWhom,
  courseSuggestions,
  duration
}) => {
  return (
    <Main>
      <BorderWrapper>
        <TopSide>
          <TagWrapper>
            <TagLabel className="tag-header" text={tagText} color="primary" />
            <DotRed />
            <Typography variant="medium/12-20" color={colorsV2.primary100}>
              {duration}
            </Typography>
          </TagWrapper>
          <Typography variant="semi-bold/20-28" color={colorsV2.black100}>
            {title}
          </Typography>
          <MainContent>
            {descriptions?.map((description, idx) => (
              <ContentWrapper
                className={cl({ 'single-content': descriptions?.length === 1 })}
                key={idx}
              >
                {descriptions?.length > 1 && <Dot />}
                <Typography variant="medium/16-24" color={colorsV2.neutral180}>
                  {description}
                </Typography>
              </ContentWrapper>
            ))}
          </MainContent>
          <CourseSuggestions>
            {courseSuggestions?.map((eachCourse, idx) => (
              <Link key={idx} to={eachCourse?.linkLevel}>
                <CourseTextLink
                  key={idx}
                  text={eachCourse?.text}
                  variant="regular/16-24"
                />
              </Link>
            ))}
          </CourseSuggestions>
          <LazyImage className="image-path" src={iconImgUrl} alt={iconImgAlt} />
          <LazyImage
            className="check-point"
            src={checkPointImg}
            alt={checkPointAlt}
          />
        </TopSide>
        <Divider />
        <BottomSide>
          <MainContent>
            <Typography variant="semi-bold/16-24" color={colorsV2.neutral200}>
              Nội dung chính
            </Typography>
            {mainContent?.map((eachContent, idx) => (
              <ContentWrapper key={idx}>
                <Dot />
                <Typography
                  variant="regular/16-24"
                  color={colorsV2.black80}
                  dangerouslySetInnerHTML={{
                    __html: eachContent
                  }}
                />
              </ContentWrapper>
            ))}
          </MainContent>
          <ForWhom>
            <Typography variant="semi-bold/16-24" color={colorsV2.neutral200}>
              Khóa học dành cho ai?
            </Typography>
            {forWhom?.map((eachItem, idx) => (
              <ContentWrapper key={idx}>
                <Dot />
                <Typography variant="regular/16-24" color={colorsV2.black80}>
                  {eachItem}
                </Typography>
              </ContentWrapper>
            ))}
          </ForWhom>
        </BottomSide>
      </BorderWrapper>
    </Main>
  );
};
