import * as React from 'react';

/* eslint-disable react/prop-types */
import { withHomePageSection } from 'components/withHomePageSection';
import { colorsV2 } from 'style/colors-v2';
import styled from 'styled-components/macro';
import { LearningPath } from 'shared/CourseLandingV3/LearningPath';
import { Typography } from 'components/DesignSystemV2/Typography';
import { TitleIcon } from 'components/TitleIcon';
import { UserPixelTracking } from 'utils/userPixelTracking';
import DolInfo from 'shared/DolInfo';
import Button from '@dolstaff/shared/es/Button';
import { IconsLogoFacebook2 } from 'components/Icon/SVGIcons';
import { fromScreen } from 'utils/media-query/responsive.util';
import { graphql, useStaticQuery, Link } from 'gatsby';

const Main = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  gap: 48px;
  padding: 0 16px;
  .headline-icon {
    display: block;
  }
  .title {
    margin: 0 auto;
    display: grid;
    grid-template-columns: 1fr auto;
    justify-content: space-between;
    text-align: center;
  }
  .link-messenger {
    width: fit-content;
    margin: auto;
  }
  .fb-mess {
    margin-right: 6px;
  }
  .btn-action {
    border-radius: 12px;
  }
  ${fromScreen(776)} {
    padding: 0;
  }
`;
const ListPath = styled.div`
  display: grid;
  gap: 40px;
  margin-bottom: 100px;
`;
const BtnWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;
const IeltsLearningPathBase = ({ data }) => {
  const onClick = () => {
    UserPixelTracking.clickEvent();
  };
  return (
    <Main>
      <TitleIcon iconHandle="RY1uavGWQqW2aZ0GGJDo" className="title">
        <Typography
          variant="bold/24-32"
          tabletVariant="bold/32-40"
          desktopVariant="bold/40-48"
          v3
          as="h2"
        >
          Lộ trình học IELTS tại DOL
        </Typography>
      </TitleIcon>
      <ListPath>
        {data?.map((eachData, idx) => {
          const {
            tagText,
            title,
            descriptions,
            iconImgUrl,
            iconImgAlt,
            mainContent,
            forWhom,
            courseSuggestions,
            duration
          } = eachData || {};
          return (
            <LearningPath
              key={idx}
              tagText={tagText}
              title={title}
              descriptions={descriptions}
              iconImgUrl={iconImgUrl}
              iconImgAlt={iconImgAlt}
              mainContent={mainContent}
              forWhom={forWhom}
              courseSuggestions={courseSuggestions}
              duration={duration}
              lineImg={
                idx % 2
                  ? 'https://gqefcpylonobj.vcdn.cloud/directus-upload/be89f1aa-685a-49e4-ab0c-0c16eaccc991.png'
                  : 'https://gqefcpylonobj.vcdn.cloud/directus-upload/6b990255-da96-4d76-bcc3-b413858c08e3.png'
              }
              classLine={idx % 2 ? 'left-line' : 'right-line'}
              isEnd={idx === data?.length - 1}
            />
          );
        })}
      </ListPath>
      <DolInfo>
        {({ dolInfo }) => (
          <Link
            target="_blank"
            href={dolInfo.messenger.href}
            rel="noreferrer noopener"
            className="link-messenger"
          >
            <Button
              size="large"
              className="btn-action"
              type="primary"
              marginBottom="0"
              onClick={onClick}
            >
              <BtnWrapper>
                <Typography
                  className="fb-mess"
                  variant="semi-bold/14-24"
                  color={colorsV2.white100}
                >
                  Liên hệ DOL tư vấn ngay
                </Typography>
                <IconsLogoFacebook2 />
              </BtnWrapper>
            </Button>
          </Link>
        )}
      </DolInfo>
    </Main>
  );
};

export const IeltsLearningPath = withHomePageSection(
  () => {
    const data = useStaticQuery(graphql`
      {
        gcms {
          jsonContanier(where: { name: "IeltsLanding_Ielts_LearningPath" }) {
            content
          }
        }
      }
    `);
    return <IeltsLearningPathBase data={data.gcms.jsonContanier.content} />;
  },
  {
    style: {
      backgroundColor: colorsV2.paper
    }
  },
  {}
);
