import * as React from 'react';

function IconCourseArrowArrow2DropDown(props) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fill="currentColor"
        d="M7.696 11.234l3.384 3.384a1.3 1.3 0 001.842 0l3.384-3.384c.823-.823.235-2.234-.928-2.234H8.61c-1.162 0-1.737 1.411-.914 2.234z"
      />
    </svg>
  );
}

export default React.memo(IconCourseArrowArrow2DropDown);
