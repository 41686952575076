import * as React from 'react';

/* eslint-disable react/prop-types */
import { withHomePageSection } from 'components/withHomePageSection';
import { colorsV2 } from 'style/colors-v2';
import { CourseHeaderWithVideo } from 'shared/CourseLandingV3/CourseHeaderWithVideo';
import { graphql, useStaticQuery } from 'gatsby';

const ToeicAndPronunciationHeaderBase = ({ data }) => {
  return <CourseHeaderWithVideo data={data} isToeic={true} />;
};

export const ToeicAndPronunciationHeader = withHomePageSection(
  () => {
    const data = useStaticQuery(graphql`
      {
        gcms {
          jsonContanier(where: { name: "ToeicAndPronunciation_Header" }) {
            content
          }
        }
      }
    `);
    return (
      <ToeicAndPronunciationHeaderBase data={data.gcms.jsonContanier.content} />
    );
  },
  {
    style: {
      backgroundColor: colorsV2.paper
    }
  },
  {}
);
