import * as React from 'react';

function IconCourseInfoFill(props) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M12 22c5.523 0 10-4.477 10-10S17.523 2 12 2 2 6.477 2 12s4.477 10 10 10zm0-15a1 1 0 100 2 1 1 0 000-2zm1 9v-4a1 1 0 10-2 0v4a1 1 0 102 0z"
        clipRule="evenodd"
      />
    </svg>
  );
}

export default React.memo(IconCourseInfoFill);
