import * as React from 'react';
import { InputGreyStyleLayout } from 'shared/InputGreyStyleLayout/InputGreyStyleLayout';
import { DolPopover } from 'shared/Popover/DolPopover';
import styled from 'styled-components';

const DropdownContent = styled.div`
  position: relative;
`;

const Container = styled.div``;

export const SelectGreyStyleInputGeneral = props => {
  const {
    placement = 'bottom-start',
    inputContent,
    dropdownContent,
    disabled = false,
    size = 'large',
    className
  } = props;
  const [visible, setVisible] = React.useState(false);
  const inputLayoutRef = React.useRef(null);

  return (
    <Container className="select-grey-style-input-general">
      <DolPopover
        placement={placement}
        trigger="click"
        overlayClassName="un-styled no-arrow custom-inner-content"
        visible={!disabled && visible}
        onVisibleChange={setVisible}
        content={({ setTooltipOpen }) => (
          <DropdownContent
            style={{ width: inputLayoutRef?.current?.offsetWidth }}
            onClick={() => setTooltipOpen(false)}
          >
            {dropdownContent}
          </DropdownContent>
        )}
        disabled={disabled}
      >
        <InputGreyStyleLayout
          className={className}
          ref={inputLayoutRef}
          size={size}
          inputContent={inputContent}
          disabled={disabled}
          active={visible}
        />
      </DolPopover>
    </Container>
  );
};
