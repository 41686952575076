import React, { useCallback, useEffect, useReducer } from 'react';
import PropTypes from 'prop-types';

import OutlineArrowIcArrowRight from '@dolstaff/shared/es/icons/OutlineArrowIcArrowRight';
import Spinner from '@dolstaff/shared/es/Spinner';
import axios from 'axios';
import styled from 'styled-components';
import { Typography } from 'components/DesignSystemV2/Typography';
import { colorsV2 } from 'style/colors-v2';
import { fromScreen } from 'utils/media-query/responsive.util';

// import fetchGraphData from '../../utils/fetchGraphData';
import SubTitle from '../MainLayout/SubTitle';
import Title from '../MainLayout/Title';
import RedButton from '../MyButton/RedButton';
// import getHallOfFameQuery from './getHallOfFameQuery';
import { useDeviceDetect3 } from '../../utils/hooks/useDeviceQuery.hook';
import ListItem from './ListItem';
import IconArrowDown from 'shared/StudentCourseLanding/icons/IconArrowDown';

const fetchHallOfFame = async ({ page, limit, program }) => {
  let programParam = '';
  switch (program) {
    case 'OTHER':
      programParam = '?program=SAT&program=TOEIC';
      break;
    case 'GMAT/GRE':
      programParam = '?program=GMAT&program=GRE';
      break;
    default:
      programParam = `?program=${program}`;
      break;
  }

  return await axios.get(
    `${process.env.NODE_ENDPOINT}/hallOfFameList${programParam}`,
    {
      params: {
        _sort:
          (program === 'IELTS' ? 'overallScore' : 'totalScore') + ',resultDate',
        _page: page,
        _limit: limit,
        _order: 'desc,desc'
      }
    }
  );
};

const reducer = (prevState, nextState) => ({ ...prevState, ...nextState });

const ITEM_PER_PAGE_DESKTOP = 6;
const ITEM_PER_PAGE_MOBILE = 3;

const HallOfFame = props => {
  const { paging, isDetailCourse, program, showTitle } = props;
  const { isMobile } = useDeviceDetect3();

  const [state, setState] = useReducer(reducer, {
    total: 0,
    loaded: 0,
    hofList: [],
    loading: false,
    size: isMobile ? ITEM_PER_PAGE_MOBILE : ITEM_PER_PAGE_DESKTOP
  });

  const { total, loaded, hofList, loading, size, page = 1 } = state;
  const restOfHallOfFame = total - loaded;

  const loadHallOfFame = useCallback(() => {
    setState({ loading: true });
    fetchHallOfFame({ page, limit: size, program })
      .then(res => {
        const list = res.data;
        setState({
          total: res.headers['x-total-count'],
          hofList: hofList.concat(list),
          loaded: loaded + list.length,
          page: page + 1,
          loading: false
        });
      })
      .catch(() => {
        setState({ loading: false });
      });
  }, [hofList, loaded, size, page, program]);

  useEffect(() => {
    loadHallOfFame();
    // Reason: Fetch once on mount
    /*eslint-disable-next-line*/
  }, []);

  return (
    <HOFContainer>
      {showTitle && (
        <Typography
          variant="bold/24-32"
          tabletVariant="bold/32-40"
          desktopVariant="bold/40-48"
          v3
          className="section-title"
          as="h2"
        >
          Bảng vàng thành tích của DOL
        </Typography>
      )}

      {isDetailCourse && (
        <Typography
          variant="bold/20-28"
          desktopVariant="bold/24-32"
          v3
          className="title-detail-course"
          as="h2"
        >
          Bảng vàng thành tích của DOL
        </Typography>
      )}

      <ListItem hallOfFameList={hofList} isDetailCourse={isDetailCourse} />
      {paging && restOfHallOfFame > 0 ? (
        <div className="load-more">
          <button onClick={loadHallOfFame}>
            <Typography variant="semi-bold/14-24" color={colorsV2.primary100}>
              Xem thêm thành tích
            </Typography>
            <IconArrowDown width={24} height={24} color={colorsV2.primary100} />
            {loading && (
              <span style={{ marginLeft: '0.5em' }}>
                <Spinner size={24} strokeWidth={6} />
              </span>
            )}
          </button>
        </div>
      ) : null}
    </HOFContainer>
  );
};

export const HOFContainer = styled.div`
  display: grid;
  grid-row-gap: 24px;

  h3,
  p {
    margin: 0;
  }

  .load-more {
    display: flex;
    justify-content: center;
    > button {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: transparent;
      border: 1px solid ${colorsV2.primary20};
      padding: 10px 0;
      border-radius: 12px;
      color: ${colorsV2.primary100};
      transition: 0.2s all;
      cursor: pointer;
    }
  }

  ${fromScreen(776)} {
    .title-detail-course {
      font-size: 24px;
      line-height: 32px;
    }
    .section-title {
      margin-bottom: 16px;
    }
  }

  ${fromScreen(1144)} {
    .section-title {
      text-align: center;
    }
  }
`;

const NoMarginTitle = styled(Title)`
  span.em {
    margin: 0;
  }
`;

const ViewAll = styled(RedButton)`
  text-align: left;
  padding-left: 0;
`;

export const ViewAllHOF = () => (
  <ViewAll to="/ielts/review">
    <span>Xem thành thích của tất cả Dolbies</span>
    <OutlineArrowIcArrowRight size={24} />
  </ViewAll>
);

export const Title1 = () => (
  <Title>
    <strong style={{ marginLeft: 0 }}>Bảng vàng thành tích</strong>
    &nbsp;của Dolbies
  </Title>
);

export const Title2 = () => (
  <NoMarginTitle>
    <span className="em" style={{ marginLeft: 0 }}>
      Bảng vàng
    </span>{' '}
    thành tích của Dolbies
  </NoMarginTitle>
);

export const DefaultHOFSubTitle = () => (
  <SubTitle>
    Trong suốt hơn 3 năm hoạt động, DOL và đội ngũ của mình đã giúp 1 bạn đạt
    8.5, 4 bạn 8.0, 40 bạn 7.5 và trên 100 bạn đạt 7.0, cùng rất nhiều bạn trên
    6.5. DOL thấy bản thân mình rất may mắn, rất hạnh phúc vì được đồng hành
    cùng các bạn đạt được những kết quả đáng tự hào trên.
  </SubTitle>
);

HallOfFame.propTypes = {
  hofList: PropTypes.arrayOf(PropTypes.shape({})),
  subTitle: PropTypes.node,
  title: PropTypes.node,
  bellowHeader: PropTypes.node,
  paging: PropTypes.bool,
  order: PropTypes.string,
  isDetailCourse: PropTypes.bool,
  program: PropTypes.string,
  showTitle: PropTypes.bool
};

HallOfFame.defaultProps = {
  hofList: [],
  bellowHeader: null,
  paging: true,
  subTitle: <DefaultHOFSubTitle />,
  title: <Title1 />,
  program: 'IELTS'
};

export default HallOfFame;
