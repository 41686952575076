import * as React from 'react';

/* eslint-disable react/prop-types */
import { withHomePageSection } from 'components/withHomePageSection';
import { colorsV2 } from 'style/colors-v2';
import { GeneralIntroduction } from 'shared/CourseLandingV3/GeneralIntroduction';
import { graphql, useStaticQuery } from 'gatsby';

const SATAndGmatGreGeneralIntroductionBase = ({ data }) => {
  return (
    <GeneralIntroduction
      title="Giới thiệu chung về SAT - GMAT/GRE"
      data={data}
    />
  );
};

export const SATAndGmatGreGeneralIntroduction = withHomePageSection(
  () => {
    const data = useStaticQuery(graphql`
      {
        gcms {
          jsonContanier(where: { name: "SATAndGmatGre_GeneralIntroduction" }) {
            content
          }
        }
      }
    `);
    return (
      <SATAndGmatGreGeneralIntroductionBase
        data={data.gcms.jsonContanier.content}
      />
    );
  },
  {
    style: {
      backgroundColor: colorsV2.black100
    }
  },
  {}
);
