import * as React from 'react';
import MainLayout from 'components/Layout/MainLayout';
import HeaderSchedulePage from 'shared/Header/HeaderSchedulePage';
import MySEO from 'shared/MySEO';
import { fromScreen } from 'utils/media-query/responsive.util';
import styled from 'styled-components/macro';
import { SATAndGmatGreHeader } from 'shared/SATAndGmatGre/SATAndGmatGreHeader';
import { SATLearningPath } from 'shared/SATAndGmatGre/SATLearningPath';
import { Courses } from 'shared/CourseLandingV3/Courses';
import { SATAndGmatGreGeneralIntroduction } from 'shared/SATAndGmatGre/SATAndGmatGreGeneralIntroduction';
import { GmatGreLearningPath } from 'shared/SATAndGmatGre/GmatGreLearningPath';
import { CourseFaqsByClassGroup } from 'shared/CourseLandingV3/CourseFaqsByClassGroup';
import { SATAndGmatGreStudentExperience } from 'shared/SATAndGmatGre/SATAndGmatGreStudentExperience';
import HallOfFame from 'shared/CourseLandingV3/HallOfFame';

const SATAndGmatGre = ({ location }) => {
  return (
    <MainLayout
      noHeader
      hideSideCTA
      renderCustomHeader={() => <HeaderSchedulePage />}
      showFooterRegister
    >
      <MySEO
        title="Chinh phục SAT - GMAT/GRE dễ dàng với DOL!"
        slug={location.pathname}
        seoTitle="Chinh phục SAT - GMAT/GRE dễ dàng với DOL!"
        seoDesc="Nền tảng tư duy đột phá, kết hợp công nghệ tiên tiến, đảm bảo lộ trình cá nhân hóa và kết quả vượt mong đợi."
        description="Nền tảng tư duy đột phá, kết hợp công nghệ tiên tiến, đảm bảo lộ trình cá nhân hóa và kết quả vượt mong đợi."
        featureImg="1le7VvNTqqwYfQNVbwTo"
      />
      <Space>
        <SATAndGmatGreHeader />
        <SATAndGmatGreGeneralIntroduction />
      </Space>
      <SpaceSAT>
        <Courses level="SAT" headerTitle="Các khóa học SAT" />
      </SpaceSAT>
      <Space>
        <Courses level="Khóa GMAT/GRE" headerTitle="Các khóa học GMAT/GRE" />
        <SATLearningPath />
        <GmatGreLearningPath />
        <HallOfFame
          program="SAT"
          title="2.000+ học viên"
          description="đạt SAT cao từ 1450+"
        />
        <SATAndGmatGreStudentExperience />
        <CourseFaqsByClassGroup classGroup="SAT" />
      </Space>
    </MainLayout>
  );
};

const SpaceSAT = styled.div`
  overflow: hidden;
  > section {
    padding-top: 64px !important;
    ${fromScreen(776)} {
      padding-top: 100px !important;
    }
  }
  .section-courses {
    padding: 0 0 0 16px !important;
  }
  ${fromScreen(776)} {
    .section-courses {
      padding: 0px !important;
    }
  }
`;

const Space = styled.div`
  overflow: hidden;
  > section {
    padding: 64px 0 !important;
    ${fromScreen(776)} {
      padding: 100px 0 !important;
    }
  }
  .section-courses {
    padding: 0 0 0 16px !important;
  }
  ${fromScreen(776)} {
    .section-courses {
      padding: 0px !important;
    }
  }
`;

export default React.memo(SATAndGmatGre);
