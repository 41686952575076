import * as React from 'react';
import MainLayout from 'components/Layout/MainLayout';
import HeaderSchedulePage from 'shared/Header/HeaderSchedulePage';
import MySEO from 'shared/MySEO';
import { fromScreen } from 'utils/media-query/responsive.util';
import styled from 'styled-components/macro';
import { Courses } from 'shared/CourseLandingV3/Courses';
import { IeltsStudentExperience } from 'shared/IeltsLanding/Ielts/IeltsStudentExperience';
import { IeltsNangCaoHeader } from 'shared/IeltsLanding/IeltsNangCao/IeltsNangCaoHeader';
import { IeltsNangCaoWhatYourProblem } from 'shared/IeltsLanding/IeltsNangCao/IeltsNangCaoWhatYourProblem';
import { IeltsNangCaoDolSolutionSection } from 'shared/IeltsLanding/IeltsNangCao/IeltsNangCaoDolSolutionSection';
import { CourseFaqsByClassGroup } from 'shared/CourseLandingV3/CourseFaqsByClassGroup';

const IeltsNangCao = ({ location }) => {
  return (
    <MainLayout
      noHeader
      hideSideCTA
      renderCustomHeader={() => <HeaderSchedulePage />}
      showFooterRegister
    >
      <MySEO
        title="IELTS nâng cao"
        slug={location.pathname}
        seoTitle="IELTS nâng cao"
        seoDesc="Nhiều bạn bị chững lại ở giai đoạn này, làm hoài không tăng điểm. Cái cần nhất lúc này là Tư Duy để tận dụng tối đa kiến thức sẵn có và bứt phá đạt điểm mong muốn. Hay nói vắn tắt “Học Tư duy chấp hết đề, tư duy ít Vocab đọc vẫn hiểu” của Linearthinking."
        description="Nhiều bạn bị chững lại ở giai đoạn này, làm hoài không tăng điểm. Cái cần nhất lúc này là Tư Duy để tận dụng tối đa kiến thức sẵn có và bứt phá đạt điểm mong muốn. Hay nói vắn tắt “Học Tư duy chấp hết đề, tư duy ít Vocab đọc vẫn hiểu” của Linearthinking."
        featureImg="1le7VvNTqqwYfQNVbwTo"
      />
      <Space>
        <IeltsNangCaoHeader />
        <IeltsNangCaoWhatYourProblem />
        <IeltsNangCaoDolSolutionSection />
        <Courses
          level="Khóa IELTS nâng cao"
          headerTitle="Các khóa học IELTS nâng cao"
        />
        <IeltsStudentExperience />
        <CourseFaqsByClassGroup classGroup="IELTS" />
      </Space>
    </MainLayout>
  );
};

const Space = styled.div`
  overflow: hidden;
  > section {
    padding: 64px 0 !important;
    ${fromScreen(776)} {
      padding: 100px 0 !important;
    }
  }
  .section-courses {
    padding: 0 0 0 16px !important;
  }
  ${fromScreen(776)} {
    .section-courses {
      padding: 0px !important;
    }
  }
`;

export default React.memo(IeltsNangCao);
