import * as React from 'react';

/* eslint-disable react/prop-types */
import { withHomePageSection } from 'components/withHomePageSection';
import { colorsV2 } from 'style/colors-v2';
import { CourseHeaderIeltsOnline } from 'shared/CourseLandingV3/CourseHeaderIeltsOnline';
import { graphql, useStaticQuery } from 'gatsby';

const IeltsOnlineHeaderBase = ({ data }) => {
  return <CourseHeaderIeltsOnline data={data} />;
};

export const IeltsOnlineHeader = withHomePageSection(
  () => {
    const data = useStaticQuery(graphql`
      {
        gcms {
          jsonContanier(where: { name: "IeltsLanding_IeltsOnline_Header" }) {
            content
          }
        }
      }
    `);
    return <IeltsOnlineHeaderBase data={data.gcms.jsonContanier.content} />;
  },
  {
    style: {
      backgroundColor: colorsV2.paper
    }
  },
  {}
);
