import { css } from 'styled-components';

export const sizeStyles = css`
  &.large {
    min-height: 46px;
    padding: 13px 16px;
  }

  &.medium {
    min-height: 40px;
    padding: 10px 12px;
  }

  &.small {
    min-height: 36px;
    padding: 8px 12px;
  }

  &.xs {
    min-height: 32px;
    padding: 6px 10px;
  }
`;
