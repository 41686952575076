/* eslint-disable react/prop-types */
import * as React from 'react';
import styled from 'styled-components/macro';
import { Typography } from 'components/DesignSystemV2/Typography';
import { colorsV2 } from 'style/colors-v2';
import { LazyImage } from 'components/LazyImage';

const Item = styled.div`
  width: 356px;
  height: fit-content;
  display: grid;
  gap: 24px;
  &:hover {
    .certificate {
      bottom: 0;
    }
  }
`;
const ImageBox = styled.div`
  position: relative;
  padding-top: 44px;
  overflow: hidden;
  .image {
    img {
      width: 356px;
      height: 264px;
      border-radius: 20px;
      object-fit: cover;
    }
  }
  .certificate {
    position: absolute;
    height: 305px;
    bottom: -100%;
    padding: 0 12px;
    z-index: 10;
    transition: all 400ms ease-in-out;
    img {
      border: 1px solid ${colorsV2.neutral60};
      border-radius: 12px;
    }
  }
`;
const LeaderBoardCard = styled.div`
  position: absolute;
  bottom: 0;
  padding: 12px;
`;
const ScoreWrapper = styled.div`
  text-align: center;
  border-radius: 16px;
  padding: 16px;
  backdrop-filter: blur(32px);
  background: rgba(255, 255, 255, 0.8);
`;

const ContentBox = styled.div`
  display: grid;
  gap: 8px;
  padding: 0 16px;
  .info-subtitle {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }
`;

export const ItemHOF = props => {
  const {
    bangVangAvatar,
    bangVangCertificate,
    bangVangDisplayName,
    // bangVangListed,
    bangVangOccupation,
    overallScore,
    totalScore,
    program
  } = props || {};
  return (
    <Item>
      <ImageBox>
        <LazyImage
          className="image"
          imgProps={{
            alt: 'bang-vang-avatar'
          }}
          handle={bangVangAvatar}
        />
        {bangVangCertificate && (
          <LazyImage
            className="certificate"
            imgProps={{
              alt: 'certificate'
            }}
            handle={bangVangCertificate}
          />
        )}

        <LeaderBoardCard>
          <ScoreWrapper>
            <Typography variant="bold/40-48" color={colorsV2.primary100}>
              {overallScore || totalScore}
            </Typography>
            <Typography variant="regular/16-24" color={colorsV2.neutral120}>
              {program}
            </Typography>
          </ScoreWrapper>
        </LeaderBoardCard>
      </ImageBox>
      <ContentBox>
        <Typography variant="semi-bold/24-32" color={colorsV2.neutral200}>
          {bangVangDisplayName}
        </Typography>
        <Typography
          variant="regular/16-24"
          color={colorsV2.neutral120}
          className="info-subtitle"
        >
          {bangVangOccupation}
        </Typography>
      </ContentBox>
    </Item>
  );
};
