import * as React from 'react';
import styled from 'styled-components';
import cl from 'classnames';
import { colorsV2 } from 'style/colors-v2';
import { Typography } from 'components/DesignSystemV2/Typography';
import { IconCourseArrowArrow2DropDown } from 'components/Icon/SVGIcons';

const Container = styled.div`
  display: flex;
  align-items: center;

  user-select: none;

  .text {
    flex: 1;
    color: ${colorsV2.neutral120};
  }

  &.selected {
    .text {
      color: ${colorsV2.neutral180};
    }
  }
`;

export const InputContentDropdownSelect = props => {
  const { text, placeholder } = props;

  return (
    <Container
      className={cl('input-content-dropdown-select', {
        selected: Boolean(text)
      })}
    >
      <Typography variant="medium/14-20" className="text">
        {text || placeholder}
      </Typography>
      <IconCourseArrowArrow2DropDown
        size={20}
        ml="8px"
        color={colorsV2.black40}
      />
    </Container>
  );
};
