import {
  arrow,
  flip,
  FloatingPortal,
  offset,
  safePolygon,
  shift,
  useClick,
  useClientPoint,
  useDismiss,
  useFloating,
  useHover,
  useInteractions,
  useRole
} from '@floating-ui/react';
import * as React from 'react';
import styled from 'styled-components';
import { TooltipArrowMain, TooltipMain } from './shareTooltipComponents';
import cl from 'classnames';
import { isNil } from 'lodash';
import renderNode, { Div } from './renderNode';

const ARROW_OFFSET = 6;
const SPACING = 6;

const TooltipTitle = styled.div`
  display: inline;
`;

export const DolTooltip = ({
  children,
  placement,
  trigger = ['hover'],
  title,
  visible,
  className,
  color,
  overlayClassName,
  maxWidth,
  onVisibleChange,
  disabled = false,
  withDismiss,
  withClientPoint = false,
  overrideHover = {},
  overrideClientPoint = {},
  zIndex
}) => {
  const finalTrigger = typeof trigger === 'string' ? [trigger] : trigger;
  const [open, onOpenChange] = React.useState(false);
  const arrowRef = React.useRef(null);
  const changeTooltipVisible = React.useCallback(
    isOpen => {
      if (disabled) {
        return;
      }

      if (onVisibleChange) {
        onVisibleChange(isOpen);
      }
      onOpenChange(isOpen);
    },
    [disabled, onVisibleChange]
  );
  const {
    refs,
    context,
    middlewareData: { arrow: { x: arrowX, y: arrowY } = {} },
    placement: floatingPlacement,
    floatingStyles,
    update
  } = useFloating({
    strategy: 'fixed',
    open,
    placement: placement,
    onOpenChange: changeTooltipVisible,
    middleware: [
      offset(
        (overlayClassName || '').includes('no-arrow')
          ? SPACING
          : ARROW_OFFSET + SPACING
      ),
      shift({ padding: 5 }),
      flip(),
      arrow({
        element: arrowRef
      })
    ]
  });

  const isTooltipOpen = React.useMemo(() => {
    if (isNil(visible)) {
      return open;
    }
    return visible;
  }, [open, visible]);

  const { getReferenceProps, getFloatingProps } = useInteractions([
    useHover(context, {
      enabled: finalTrigger.includes('hover'),
      move: false,
      handleClose: safePolygon({
        blockPointerEvents: false
      }),
      ...overrideHover
    }),
    useRole(context, {
      role: 'tooltip'
    }),
    useClick(context, {
      enabled: finalTrigger.includes('click'),
      keyboardHandlers: false
    }),
    useClientPoint(context, {
      enabled: withClientPoint,
      axis: 'x',
      ...overrideClientPoint
    }),
    useDismiss(context, {
      enabled: withDismiss || finalTrigger.includes('click'),
      referencePress: finalTrigger.includes('hover')
    })
  ]);
  const staticSide = React.useMemo(() => {
    return {
      top: 'bottom',
      right: 'left',
      bottom: 'top',
      left: 'right'
    }[floatingPlacement.split('-')[0]];
  }, [floatingPlacement]);

  return (
    <>
      <TooltipTitle
        ref={refs.setReference}
        className={cl('tooltip-title', className)}
        {...getReferenceProps()}
      >
        {renderNode(Div, children, {
          open,
          setTooltipOpen: changeTooltipVisible,
          forceUpdate: update
        })}
      </TooltipTitle>
      {isTooltipOpen && (
        <FloatingPortal id="dol-tooltip-portal">
          <TooltipMain
            className={overlayClassName}
            ref={refs.setFloating}
            $color={color}
            $maxWidth={maxWidth}
            $zIndex={zIndex}
            style={floatingStyles}
            {...getFloatingProps()}
          >
            {renderNode(Div, title, {
              setTooltipOpen: changeTooltipVisible,
              forceUpdate: update
            })}
            <TooltipArrowMain
              className="popover-arrow"
              ref={arrowRef}
              $color={color}
              $placement={context?.placement}
              style={{
                left: arrowX != null ? `${arrowX}px` : '',
                top: arrowY != null ? `${arrowY}px` : '',
                [staticSide]: `-${ARROW_OFFSET}px`
              }}
            />
          </TooltipMain>
        </FloatingPortal>
      )}
    </>
  );
};
