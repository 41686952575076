import * as React from 'react';
import styled from 'styled-components/macro';
import { v4 as uuidv4 } from 'uuid';

/* eslint-disable react/prop-types */
import { Typography } from 'components/DesignSystemV2/Typography';
import { withHomePageSection } from 'components/withHomePageSection';
import { colorsV2 } from 'style/colors-v2';
import { fromScreen } from 'utils/media-query/responsive.util';
import { graphql, useStaticQuery } from 'gatsby';
import Button from '@dolstaff/shared/es/Button';
import { LazyImage } from 'components/LazyImage';
import { IconArrowArrowRightLine } from 'components/Icon/SVGIcons';
import { UserPixelTracking } from 'utils/userPixelTracking';
import { useModalController } from './hooks/useModalHook';
import RegisterForm from './RegisterForm';

const Main = styled.div`
  margin-top: 60px;
`;

const Container = styled.div`
  position: relative;
  background-image: url('https://gqefcpylonobj.vcdn.cloud/directus-upload/71ddae71-59e2-490f-b5a7-70622063b9b8.png');
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 28px;
  .sale-banner-mascot-wow {
    position: relative;
    height: 311px;
    width: 311px;
    margin: auto;
    right: 0px;
    bottom: 0px;
    img {
      width: 100%;
      height: 100%;
    }
  }
  ${fromScreen(776)} {
    background-size: 100% 100%;
    .sale-banner-mascot-wow {
      position: absolute;
      height: 309px;
      width: unset;
    }
  }
  ${fromScreen(1144)} {
    .sale-banner-mascot-wow {
      height: 412px;
    }
  }
`;
const Content = styled.div`
  width: 100%;
  display: grid;
  gap: 26px;
  padding: 46px 16px;
  .register {
    background: ${colorsV2.neutral20};
    border-radius: 12px;
    width: fit-content;
  }
  ${fromScreen(776)} {
    width: 354px;
    margin-left: 24px;
    padding: 46px 0;
  }
  ${fromScreen(1144)} {
    width: 544px;
    margin-left: 80px;
  }
`;

const Text = styled.div`
  display: grid;
  gap: 16px;
`;

const ButtonText = styled.div`
  display: grid;
  grid-template-columns: max-content auto;
  align-items: center;
  gap: 8px;
`;
const SaleBannerSectionBase = ({ data }) => {
  const { mascotWowImage, formLink } = data || {};
  const { isOpen, openModal, closeModal } = useModalController();

  const onClick = e => {
    const uid = uuidv4();
    UserPixelTracking.clickEventPixelSDK(uid);
    UserPixelTracking.clickEvent(uid);
    openModal();
  };

  return (
    <Main>
      <Container>
        <Content>
          <Text>
            <Typography variant="medium/16-24" color={colorsV2.white100}>
              Đăng ký ghi danh sớm
            </Typography>
            <Typography
              variant="bold/24-32"
              tabletVariant="bold/32-40"
              desktopVariant="bold/40-48"
              color={colorsV2.white100}
              v3
            >
              Đăng ký tìm hiểu chương trình, để được ưu tiên đăng ký sớm
            </Typography>
          </Text>
          <Button
            size="large"
            className="btn-action register"
            type="secondary"
            marginBottom="0"
            noBorder
          >
            <ButtonText>
              <Typography
                variant="semi-bold/16-24"
                color={colorsV2.neutral180}
                onClick={onClick}
              >
                Đăng ký ngay{' '}
              </Typography>
              <IconArrowArrowRightLine color={colorsV2.neutral180} />
            </ButtonText>
          </Button>
        </Content>
        <LazyImage
          className="sale-banner-mascot-wow"
          src={mascotWowImage}
          alt="dol-mascot-wow"
        />
      </Container>
      <RegisterForm closeModal={closeModal} isOpen={isOpen} />
    </Main>
  );
};

export const SaleBannerSection = withHomePageSection(
  () => {
    const data = useStaticQuery(graphql`
      {
        gcms {
          jsonContanier(where: { name: "Ielts_Junior_Sale_Banner" }) {
            content
          }
        }
      }
    `);
    return <SaleBannerSectionBase data={data.gcms.jsonContanier.content} />;
  },
  {
    style: {
      backgroundColor: colorsV2.white100
    }
  },
  {}
);
