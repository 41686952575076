import * as React from 'react';
import MainLayout from 'components/Layout/MainLayout';
import HeaderSchedulePage from 'shared/Header/HeaderSchedulePage';
import MySEO from 'shared/MySEO';
import { fromScreen } from 'utils/media-query/responsive.util';
import styled from 'styled-components/macro';
import { Courses } from 'shared/CourseLandingV3/Courses';
import { IeltsStudentExperience } from 'shared/IeltsLanding/Ielts/IeltsStudentExperience';
import { IeltsCoBanHeader } from 'shared/IeltsLanding/IeltsCoBan/IeltsCoBanHeader';
import { IeltsCoBanWhatYourProblem } from 'shared/IeltsLanding/IeltsCoBan/IeltsCoBanWhatYourProblem';
import { IeltsCoBanDolSolutionSection } from 'shared/IeltsLanding/IeltsCoBan/IeltsCoBanDolSolutionSection';
import { CourseFaqsByClassGroup } from 'shared/CourseLandingV3/CourseFaqsByClassGroup';

const IeltsCoBan = ({ location }) => {
  return (
    <MainLayout
      noHeader
      hideSideCTA
      renderCustomHeader={() => <HeaderSchedulePage />}
      showFooterRegister
    >
      <MySEO
        title="IELTS cơ bản"
        slug={location.pathname}
        seoTitle="IELTS cơ bản"
        seoDesc="Nắm chắc tư duy đọc, viết, nói 1 câu tiến đến đọc, viết, nói thành thạo 1 đoạn. Quan trọng nhất ở giai đoạn này là mình cần xây nền chắc chắn, học bản chất để bài thi IELTS hay TOEIC hay bất cứ bài thi Tiếng Anh nào cũng như nhau cả."
        description="Nắm chắc tư duy đọc, viết, nói 1 câu tiến đến đọc, viết, nói thành thạo 1 đoạn. Quan trọng nhất ở giai đoạn này là mình cần xây nền chắc chắn, học bản chất để bài thi IELTS hay TOEIC hay bất cứ bài thi Tiếng Anh nào cũng như nhau cả."
        featureImg="1le7VvNTqqwYfQNVbwTo"
      />
      <Space>
        <IeltsCoBanHeader />
        <IeltsCoBanWhatYourProblem />
        <IeltsCoBanDolSolutionSection />
        <Courses
          level="Khóa IELTS cơ bản"
          headerTitle="Các khóa học IELTS cơ bản"
        />
        <IeltsStudentExperience />
        <CourseFaqsByClassGroup classGroup="IELTS" />
      </Space>
    </MainLayout>
  );
};

const Space = styled.div`
  overflow: hidden;
  > section {
    padding: 64px 0 !important;
    ${fromScreen(776)} {
      padding: 100px 0 !important;
    }
  }
  .section-courses {
    padding: 0 0 0 16px !important;
  }
  ${fromScreen(776)} {
    .section-courses {
      padding: 0px !important;
    }
  }
`;

export default React.memo(IeltsCoBan);
