import * as React from 'react';
import MainLayout from 'components/Layout/MainLayout';
import HeaderSchedulePage from 'shared/Header/HeaderSchedulePage';
import MySEO from 'shared/MySEO';
import { fromScreen } from 'utils/media-query/responsive.util';
import styled from 'styled-components/macro';
import { Courses } from 'shared/CourseLandingV3/Courses';
import { IeltsStudentExperience } from 'shared/IeltsLanding/Ielts/IeltsStudentExperience';
import { IeltsOnlineHeader } from 'shared/IeltsLanding/IeltsOnline/IeltsOnlineHeader';
import { AboutDol } from 'shared/SmartLearning/AboutDol';
import { IeltsOnlineDifferences } from 'shared/IeltsLanding/IeltsOnline/IeltsOnlineDifferences';
import { CourseFaqsByClassGroup } from 'shared/CourseLandingV3/CourseFaqsByClassGroup';
import HallOfFame from 'shared/CourseLandingV3/HallOfFame';

const IeltsOnline = ({ location }) => {
  return (
    <MainLayout
      noHeader
      hideSideCTA
      renderCustomHeader={() => <HeaderSchedulePage />}
      showFooterRegister
    >
      <MySEO
        title="Khoá học Online trải nghiệm như Offline"
        slug={location.pathname}
        seoTitle="Khoá học Online trải nghiệm như Offline"
        seoDesc="DOL English mang đến giải pháp học online hiện đại, kết hợp công nghệ AI và phương pháp Linearthinking độc quyền, giúp bạn chinh phục mọi mục tiêu dù ở bất kỳ đâu.DOL English mang đến giải pháp học online hiện đại, kết hợp công nghệ AI và phương pháp Linearthinking độc quyền, giúp bạn chinh phục mọi mục tiêu dù ở bất kỳ đâu."
        description="DOL English mang đến giải pháp học online hiện đại, kết hợp công nghệ AI và phương pháp Linearthinking độc quyền, giúp bạn chinh phục mọi mục tiêu dù ở bất kỳ đâu.DOL English mang đến giải pháp học online hiện đại, kết hợp công nghệ AI và phương pháp Linearthinking độc quyền, giúp bạn chinh phục mọi mục tiêu dù ở bất kỳ đâu."
        featureImg="1le7VvNTqqwYfQNVbwTo"
      />
      <Space>
        <IeltsOnlineHeader />
        <AboutDol
          content={`“Tại DOL English, học online không chỉ đơn thuần là học qua mạng mà còn là một trải nghiệm học tập hiệu quả, cá nhân hóa và dễ dàng tiếp cận. Với công nghệ tiên tiến và phương pháp "Linearthinking", chúng tôi cam kết mang đến cho bạn một hành trình học tập thú vị, tiện lợi, và đạt kết quả cao.”`}
        />
        <IeltsOnlineDifferences />
        <Courses
          level="Khóa IELTS cấp tốc"
          headerTitle="Các khóa học IELTS cấp tốc"
        />
        <HallOfFame
          program="IELTS"
          title="2.000+ học viên"
          description="đạt IELTS cao từ 7.0"
        />
        <IeltsStudentExperience />
        <CourseFaqsByClassGroup classGroup="IELTS" />
      </Space>
    </MainLayout>
  );
};

const Space = styled.div`
  overflow: hidden;
  > section {
    padding: 64px 0 !important;
    ${fromScreen(776)} {
      padding: 100px 0 !important;
    }
  }
  .section-courses {
    padding: 0 0 0 16px !important;
  }
  ${fromScreen(776)} {
    .section-courses {
      padding: 0px !important;
    }
  }
`;

export default React.memo(IeltsOnline);
