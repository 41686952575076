import { colorsV2 } from 'style/colors-v2';
import { css } from 'styled-components';

export const typeStyles = css`
  &.info {
    background-color: ${colorsV2.blue10};

    .text {
      color: ${colorsV2.blue100};
    }
    .close-button {
      color: ${colorsV2.blue80};
    }
  }

  &.success {
    background-color: ${colorsV2.green10};

    .text {
      color: ${colorsV2.green100};
    }
    .close-button {
      color: ${colorsV2.green60};
    }
  }

  &.warning {
    background-color: ${colorsV2.yellow10};

    .text {
      color: ${colorsV2.yellow140};
    }
    .close-button {
      color: ${colorsV2.yellow80};
    }
  }

  &.error {
    background-color: ${colorsV2.red10};

    .text {
      color: ${colorsV2.red140};
    }
    .close-button {
      color: ${colorsV2.red80};
    }
  }

  &.with-border {
    &.info {
      outline: 1px solid ${colorsV2.blue100};
    }

    &.success {
      outline: 1px solid ${colorsV2.green100};
    }

    &.warning {
      outline: 1px solid ${colorsV2.yellow100};
    }

    &.error {
      outline: 1px solid ${colorsV2.red100};
    }
  }
`;
