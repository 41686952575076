import * as React from 'react';
import styled from 'styled-components';
import {
  useCoursesWithTeacher,
  groupCoursesForDisplay
} from 'shared/CourseIeltsDetail/dataMapper';
import DetailTemplate from './component/DetailTemplate';
import { colorsV2 } from 'style/colors-v2';
import { useCoursesGroupData } from 'shared/CourseLanding/data/useCoursesGroupData';
import { Typography } from 'components/DesignSystemV2/Typography';
import cl from 'classnames';
import { withHomePageSection } from 'components/withHomePageSection';
import useGetCourseList from './data-mapper';

const CourseNameWrapper = styled.div`
  overflow-y: hidden;
  overflow-x: auto;
  scroll-snap-type: x mandatory;

  scroll-behavior: smooth;
  -webkit-overflow-scrolling: touch;

  /* Hide scrollbar for Chrome, Safari and Opera */

  &::-webkit-scrollbar {
    display: none;
  }

  /* Hide scrollbar for IE, Edge and Firefox */
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */

  margin-top: 20px;
  display: grid;
  grid-auto-flow: column;
  place-items: center;
  max-width: 712px;

  .course-name {
    min-width: 150px;
    cursor: pointer;
    width: 100%;
    text-align: center;
    > p {
      padding: 12px 0;
      border-bottom: 1px solid ${colorsV2.gray20};

      &:hover {
        color: ${colorsV2.black100};
      }

      &.selected {
        color: ${colorsV2.primary100};
        border-bottom: 2px solid ${colorsV2.primary100};
      }
    }
  }
  .course-sat {
    min-width: 200px;
  }
`;

const Layout = ({ level }) => {
  const { courseList } = useGetCourseList(level);

  const { courseWithTeachers } = useCoursesWithTeacher();
  const { desktopGroupCourses } = useCoursesGroupData();
  const sortedCourses = groupCoursesForDisplay(
    courseWithTeachers,
    desktopGroupCourses
  );

  const [selected, setSelected] = React.useState(sortedCourses[level][0].level);

  return (
    <div>
      <TabCourses
        selected={selected}
        setSelected={setSelected}
        nameCourseList={sortedCourses[level]}
      />
      {courseList.map((course, index) => {
        const { level } = course.level;
        if (level === selected) {
          return (
            <DetailTemplate
              key={index}
              otherClasses={course.otherClasses}
              level={course.level}
              rating={course.rating}
              syllabus={course.syllabusa}
            />
          );
        }
      })}
    </div>
  );
};

export default Layout;

const TabCourses = withHomePageSection(
  ({ setSelected, selected, nameCourseList }) => (
    <CourseNameWrapper>
      {nameCourseList.map(elm => (
        <div
          className={cl('course-name', {
            'course-sat': elm.classGroup === 'Khóa SAT'
          })}
          key={elm.id}
          onClick={() => setSelected(elm.level)}
        >
          <Typography
            variant="semi-bold/16-24"
            color={colorsV2.black80}
            className={cl({ selected: elm.level === selected })}
          >
            {elm.name.replace('Khóa ', '')}
          </Typography>
        </div>
      ))}
    </CourseNameWrapper>
  ),
  {}
);
