import * as React from 'react';

/* eslint-disable react/prop-types */
import { withHomePageSection } from 'components/withHomePageSection';
import { colorsV2 } from 'style/colors-v2';
import { StudentExperience } from 'shared/CourseLandingV3/StudentExperience';
import { graphql, useStaticQuery } from 'gatsby';

const SATAndGmatGreStudentExperienceBase = ({ data }) => {
  return <StudentExperience data={data} />;
};

export const SATAndGmatGreStudentExperience = withHomePageSection(
  () => {
    const data = useStaticQuery(graphql`
      {
        gcms {
          jsonContanier(where: { name: "SATAndGmatGre_StudentExperience" }) {
            content
          }
        }
      }
    `);
    return (
      <SATAndGmatGreStudentExperienceBase
        data={data.gcms.jsonContanier.content.info}
      />
    );
  },
  {
    style: {
      backgroundColor: colorsV2.white100
    }
  },
  {}
);
