import * as React from 'react';

/* eslint-disable react/prop-types */
import { withHomePageSection } from 'components/withHomePageSection';
import { colorsV2 } from 'style/colors-v2';
import styled from 'styled-components/macro';
import { LearningPath } from 'shared/CourseLandingV3/LearningPath';
import { Typography } from 'components/DesignSystemV2/Typography';
import { TitleIcon } from 'components/TitleIcon';
import { UserPixelTracking } from 'utils/userPixelTracking';
import DolInfo from 'shared/DolInfo';
import Button from '@dolstaff/shared/es/Button';
import { IconsLogoFacebook2 } from 'components/Icon/SVGIcons';
import { Link } from 'gatsby';
import { fromScreen } from 'utils/media-query/responsive.util';

const Main = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  gap: 48px;
  padding: 0 16px;
  .headline-icon {
    display: block;
  }
  .title {
    margin: 0 auto;
    display: grid;
    grid-template-columns: 1fr auto;
    justify-content: space-between;
    text-align: center;
  }
  .link-messenger {
    width: fit-content;
    margin: auto;
  }
  .fb-mess {
    margin-right: 6px;
  }
  .btn-action {
    border-radius: 12px;
  }
  ${fromScreen(776)} {
    padding: 0;
  }
`;
const ListPath = styled.div`
  display: grid;
  gap: 40px;
  margin-bottom: 100px;
`;
const BtnWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;
const SATLearningPathBase = ({ data }) => {
  const onClick = () => {
    UserPixelTracking.clickEvent();
  };
  return (
    <Main>
      <TitleIcon iconHandle="RY1uavGWQqW2aZ0GGJDo" className="title">
        <Typography
          variant="bold/24-32"
          tabletVariant="bold/32-40"
          desktopVariant="bold/40-48"
          v3
          as="h2"
        >
          Lộ trình học SAT tại DOL
        </Typography>
      </TitleIcon>
      <ListPath>
        {data?.map((eachData, idx) => {
          const {
            tagText,
            title,
            descriptions,
            iconImgUrl,
            iconImgAlt,
            mainContent,
            forWhom,
            courseSuggestions,
            duration
          } = eachData || {};
          return (
            <LearningPath
              key={idx}
              tagText={tagText}
              title={title}
              descriptions={descriptions}
              iconImgUrl={iconImgUrl}
              iconImgAlt={iconImgAlt}
              mainContent={mainContent}
              forWhom={forWhom}
              courseSuggestions={courseSuggestions}
              duration={duration}
              lineImg={
                idx % 2
                  ? 'https://gqefcpylonobj.vcdn.cloud/directus-upload/be89f1aa-685a-49e4-ab0c-0c16eaccc991.png'
                  : 'https://gqefcpylonobj.vcdn.cloud/directus-upload/6b990255-da96-4d76-bcc3-b413858c08e3.png'
              }
              classLine={idx % 2 ? 'left-line' : 'right-line'}
              isEnd={idx === data?.length - 1}
            />
          );
        })}
      </ListPath>
      <DolInfo>
        {({ dolInfo }) => (
          <Link
            target="_blank"
            href={dolInfo.messenger.href}
            rel="noreferrer noopener"
            className="link-messenger"
          >
            <Button
              size="large"
              className="btn-action"
              type="primary"
              marginBottom="0"
              onClick={onClick}
            >
              <BtnWrapper>
                <Typography
                  className="fb-mess"
                  variant="semi-bold/14-24"
                  color={colorsV2.white100}
                >
                  Liên hệ DOL tư vấn ngay
                </Typography>
                <IconsLogoFacebook2 />
              </BtnWrapper>
            </Button>
          </Link>
        )}
      </DolInfo>
    </Main>
  );
};

export const SATLearningPath = withHomePageSection(
  () => {
    const data = [
      {
        tagText: 'Xây nền',
        title: 'Xây dựng nền tảng và làm quen đề thi',
        descriptions: [
          'Củng cố ngữ pháp, từ vựng, và toán cơ bản.',
          'Hiểu cấu trúc và cách làm bài SAT.'
        ],
        iconImgUrl:
          'https://gqefcpylonobj.vcdn.cloud/directus-upload/7d61fc71-4a31-48b5-9599-f8e5394bbb34.png',
        iconImgAlt: 'sat-and-gmat-gre-path-01',
        mainContent: [
          "<span class='high-light'>Reading:</span> Hiểu ý chính, từ vựng ngữ cảnh.",
          "<span class='high-light'>Math:</span> Toán cơ bản (đại số, hình học).",
          "<span class='high-light'>Writing:</span> Ngữ pháp, sửa lỗi câu."
        ],
        forWhom: [
          'Học sinh mới bắt đầu tìm hiểu SAT hoặc mất căn bản.',
          'Điểm xuất phát thấp (< 800).'
        ],
        courseSuggestions: [
          { text: 'SAT 1', linkLevel: '/sat' },
          { text: 'SAT 2', linkLevel: '/sat' }
        ],
        duration: '8 tuần'
      },
      {
        tagText: 'Tăng tốc',
        title: 'Phát triển kỹ năng và chiến thuật làm bài',
        descriptions: [
          'Nâng cao kỹ năng đọc hiểu, viết luận, và giải toán.',
          'Thành thạo các chiến lược làm bài hiệu quả.'
        ],
        iconImgUrl:
          'https://gqefcpylonobj.vcdn.cloud/directus-upload/3424b348-710f-42b6-ac58-a0fc8c316b8a.png',
        iconImgAlt: 'sat-and-gmat-gre-path-02',
        mainContent: [
          "<span class='high-light'>Reading:</span> Chiến thuật làm bài từng đoạn, phân tích câu hỏi bẫy.",
          "<span class='high-light'>Math:</span> Toán nâng cao (thống kê, toán ứng dụng).",
          "<span class='high-light'>Writing:</span> Sửa lỗi câu phức tạp, nâng cấp luận điểm."
        ],
        forWhom: [
          'Học sinh đã có nền tảng cơ bản nhưng cần cải thiện kỹ năng làm bài.',
          'Điểm xuất phát từ 1000-1100.'
        ],
        courseSuggestions: [
          { text: 'SAT 3', linkLevel: '/sat' },
          { text: 'SAT 4', linkLevel: '/sat' }
        ],
        duration: '8 tuần'
      },
      {
        tagText: 'Về đích',
        title: 'Luyện tập chuyên sâu và ôn thi thực tế',
        descriptions: [
          'Tăng tốc độ làm bài và kỹ năng phân tích.',
          'Chuẩn bị toàn diện cho kỳ thi.'
        ],
        iconImgUrl:
          'https://gqefcpylonobj.vcdn.cloud/directus-upload/7c08a21c-9215-412c-961f-20ea2c0dcb8b.png',
        iconImgAlt: 'sat-and-gmat-gre-path-03',
        mainContent: [
          'Luyện đề SAT chuẩn quốc tế.',
          'Phân tích lỗi sai, tối ưu hóa cách chọn đáp án.',
          'Rèn luyện kỹ năng quản lý thời gian.'
        ],
        forWhom: [
          'Học sinh đã sẵn sàng cho kỳ thi nhưng muốn đạt điểm cao hơn.',
          'Điểm xuất phát từ 1200-1300.'
        ],
        courseSuggestions: [
          { text: 'SAT 5', linkLevel: '/sat' },
          { text: 'SAT 6', linkLevel: '/sat' }
        ],
        duration: '6 tuần'
      }
    ];
    return <SATLearningPathBase data={data} />;
  },
  {
    style: {
      backgroundColor: colorsV2.paper
    }
  },
  {}
);
