import React from 'react';
import { InputContentGeneral } from 'shared/InputContentGeneral/InputContentGeneral';
import { InputGreyStyleLayout } from 'shared/InputGreyStyleLayout/InputGreyStyleLayout';
import { InputGroupGreyStyleLayout } from 'shared/InputGroupGreyStyleLayout/InputGroupGreyStyleLayout';
import { InputItemLabel } from 'shared/InputItemLabel/InputItemLabel';
import { colorsV2 } from 'style/colors-v2';
import { Typography } from 'components/DesignSystemV2/Typography';

const PhoneItem = props => {
  const { value, onChange, disabled = false, errorMessage = '' } = props;
  return (
    <InputGroupGreyStyleLayout
      className="form-item-phone"
      labelContent={
        <InputItemLabel text="Số điện thoại Phụ huynh" required hasColon />
      }
      inputContent={
        <InputGreyStyleLayout
          size="large"
          inputContent={
            <InputContentGeneral
              placeholder="Nhập số điện thoại Phụ huynh"
              value={value}
              onChange={onChange}
              disabled={disabled}
            />
          }
        />
      }
      error={Boolean(errorMessage)}
      captionContent={
        <Typography variant="medium/14-20" color={colorsV2.primary100}>
          {errorMessage}
        </Typography>
      }
    />
  );
};

export default PhoneItem;
