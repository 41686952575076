import { graphql, useStaticQuery } from 'gatsby';
import {
  useCoursesWithTeacher,
  groupCoursesForDisplay,
  groupCoursesIeltsForDisplay
} from 'shared/CourseIeltsDetail/dataMapper';

import { useCoursesGroupData } from 'shared/CourseLanding/data/useCoursesGroupData';

const useGetCourseList = level => {
  const { syllabus, levels, levelsGroupByClassGroup, ratings } =
    useStaticQuery(graphql`
      {
        syllabus: allGoogleSpreadsheetDolLandingPageAcaSyllabusByLevel {
          group(field: level) {
            fieldValue
            nodes {
              buoiType
              durationInMinutes
              iconHandle
              id
              level
              session
              syllabus
              sessionTitle
              syllabusType
              totalHours
              totalSessions
              totalWeeks
            }
          }
        }
        levels: allGoogleSpreadsheetDolLandingPageMarLevelData(
          filter: { levelDisplay: { regex: "/^((?!online).)*$/i" } }
        ) {
          nodes {
            levelVideo
            levelVideoThumbnailHandle
            availableSeat
            classCode
            classDate
            classGroup
            classListing
            classTime
            inputLevel
            level
            slug
            levelDescription
            levelDisplay
            levelDisplayShorten
            outputLevel
            startDate
            teacherRw
            teacherSl
            levelTotalHour
            levelSubtext
            levelInputOutputMobile
            levelInputOutputDesktop
            levelBestseller
            levelUpdated
            numOfStudent
            whatYouGet1
            whatYouGet2
            whatYouGet3
            whatYouGet4
            whatYouGet5
            whatYouGet6
            whatYouGet7
            whatYouGet8
          }
        }
        levelsGroupByClassGroup: allGoogleSpreadsheetDolLandingPageMarLevelData(
          filter: { levelDisplay: { regex: "/^((?!online).)*$/i" } }
        ) {
          group(field: classGroup) {
            fieldValue
            nodes {
              levelVideo
              availableSeat
              classCode
              classDate
              classGroup
              classListing
              classTime
              inputLevel
              level
              slug
              levelDescription
              levelDisplay
              outputLevel
              startDate
              teacherRw
              teacherSl
              levelBestseller
              levelSubtext
              levelInputOutputMobile
              levelInputOutputDesktop
            }
          }
        }
        teachers: allGoogleSpreadsheetDolLandingPageTeacherInfo {
          nodes {
            name
            displayName
            fullName
            feedbackScore
            listingImageHandler
            feedbackReceive
          }
        }
        ratings: allGoogleSpreadsheetDolLandingPageRatingByLevel {
          nodes {
            count
            level
            average
          }
        }
      }
    `);

  const { courseWithTeachers } = useCoursesWithTeacher();
  const { desktopGroupCourses } = useCoursesGroupData();
  const sortedCourses = groupCoursesForDisplay(
    courseWithTeachers,
    desktopGroupCourses
  );

  const sortedIeltsCourses = groupCoursesIeltsForDisplay(
    courseWithTeachers,
    desktopGroupCourses
  );

  const courseList = sortedCourses[level].map(item => {
    const levelObject = levels.nodes.find(lvl => lvl.level === item.level);
    const levelRating = ratings.nodes.find(
      rating => rating.level === levelObject.level
    );
    const classGroup = levelsGroupByClassGroup.group.find(
      gr => gr.fieldValue === levelObject.classGroup
    );
    let otherClasses = [];
    if (classGroup) {
      otherClasses = sortedIeltsCourses['Khóa học khác'].filter(lvl => {
        return lvl?.classGroup !== levelObject?.classGroup;
      });
    }

    const level = {
      ...levelObject,
      isBestSeller: levelObject.levelBestseller === 'TRUE',
      updatedAt: levelObject.levelUpdated
    };
    const syllabusa = syllabus.group.find(
      group => group.fieldValue === item.level
    );
    const rating = {
      ...levelRating,
      starsInFloat: levelRating?.average ? levelRating?.average / 2 : 0
    };
    return { level, syllabusa, rating, otherClasses };
  });

  return { courseList };
};

export default useGetCourseList;
