import * as React from 'react';
import styled from 'styled-components/macro';
import { LazyImage } from 'components/LazyImage';
import { Typography } from 'components/DesignSystemV2/Typography';
import { colorsV2 } from 'style/colors-v2';
import { fromScreen } from 'utils/media-query/responsive.util';

const Main = styled.div`
  display: grid;
  gap: 48px;
  .header-title {
    text-align: center;
  }
`;
const MainContent = styled.div`
  width: 100%;
  margin: auto;
  display: grid;
  grid-template-columns: 1fr;
  gap: 40px;
  ${fromScreen(776)} {
    width: 720px;
    grid-template-columns: repeat(2, 1fr);
  }
`;

const Wrapper = styled.div`
  display: grid;
  gap: 20px;
`;
const ListContent = styled.div`
  display: grid;
  gap: 8px;
`;

const ContentWrapper = styled.div`
  display: grid;
  grid-template-columns: max-content auto;
  gap: 8px;
  .high-light {
    font-weight: 700;
  }
`;
const Dot = styled.div`
  width: 5px;
  height: 5px;
  border-radius: 50%;
  background: ${colorsV2.white100};
  margin-top: 10px;
`;
export const GeneralIntroduction = ({ title = '', data }) => {
  return (
    <Main>
      <Typography
        variant="bold/24-32"
        tabletVariant="bold/32-40"
        desktopVariant="bold/40-48"
        v3
        as="h2"
        color={colorsV2.white100}
        className="header-title"
      >
        {title}
      </Typography>
      <MainContent>
        {data?.map((eachItem, idx) => {
          const { imgUrl, imgAlt, descriptions } = eachItem || {};
          return (
            <Wrapper key={idx}>
              <LazyImage src={imgUrl} alt={imgAlt} />
              <ListContent>
                {descriptions?.map((description, desIdx) => (
                  <ContentWrapper key={desIdx}>
                    <Dot />
                    <Typography
                      variant="regular/16-24"
                      color={colorsV2.white100}
                      dangerouslySetInnerHTML={{
                        __html: description
                      }}
                    />
                  </ContentWrapper>
                ))}
              </ListContent>
            </Wrapper>
          );
        })}
      </MainContent>
    </Main>
  );
};
