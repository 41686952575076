import OutlineArrowChevronRight from '@dolstaff/shared/es/icons/OutlineArrowChevronRight';
import cl from 'classnames';
import * as React from 'react';
import styled from 'styled-components';

import { colorsV2 } from '../style/colors-v2';
import { checkIfLinkOfCurrentPageIsActiveByPath } from '../utils/checkIfLinkOfCurrentPageIsActive';
import { fromScreen } from '../utils/media-query/responsive.util';
import { NavMenuItem } from './NavMenuItem';

const Main = styled.div`
  padding-top: 32px;
  padding-bottom: 80px;
  display: grid;
  height: 100%;
  grid-auto-rows: max-content;
  gap: 8px;
  overflow: auto;
  scrollbar-gutter: stable;
  .nav-list {
    padding: 12px 10px 12px 20px;
    cursor: pointer;
    a {
      font-size: 14px;
      font-weight: 700;
      line-height: 20px;
      cursor: pointer;
      color: ${colorsV2.black100};
    }
    &:hover,
    &.active {
      background-color: rgb(244, 244, 246);
      box-shadow: none;
      border-bottom: none;
      color: ${colorsV2.primary100};
      a {
        color: ${colorsV2.primary100};
      }
    }
  }

  .group-wrapper {
    display: none;
    position: relative;
  }

  .group-vertical-line {
    width: 1px;
    height: 100%;
    background: ${colorsV2.gray20};
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    z-index: -1;
  }

  .sub-menu {
    display: grid;
    align-items: center;
    grid-auto-flow: column;
    grid-auto-columns: minmax(0, 1fr) max-content;
    gap: 4px;
    padding: 12px 10px 12px 20px;
    cursor: pointer;
    .icon-arrow {
      transition: 0.2s ease;
      transform: rotate(90deg);
      color: ${colorsV2.black40};
    }

    &:hover,
    &.active {
      background-color: ${colorsV2.gray10};
      box-shadow: none;
      border-bottom: none;
      color: ${colorsV2.primary100};
      a {
        color: ${colorsV2.primary100};
      }
    }
  }

  .sub-menu-list {
    cursor: pointer;
    a {
      font-size: 14px;
      font-weight: 700;
      line-height: 20px;
      cursor: pointer;
      color: ${colorsV2.black100};
    }

    &.active {
      .sub-menu {
        div,
        a {
          color: ${colorsV2.primary100};
        }
      }
    }

    &.expanded {
      .group-wrapper {
        display: block;
      }
      .sub-menu {
        .icon-arrow {
          transform: rotate(270deg);
        }
      }
    }
  }

  .group-item {
    position: relative;
    margin-left: 20px;
    .nav-menu-item a {
      width: 100%;
      padding: 12px 20px 12px 12px;
      color: ${colorsV2.black100};
      font-weight: 500;
    }
    &.group-item-children {
      a {
        font-weight: 400;
      }
    }
    &.active,
    &:hover {
      > .nav-menu-item a {
        color: ${colorsV2.primary100};
      }
    }
  }

  ${fromScreen(1366)} {
    display: none;
  }
`;

const normalizeListItem = list => {
  const newList = [];

  list.forEach(item => {
    if (item.type === 'groups' || item.type === 'groups-v3') {
      item.subMenu.forEach(ssMenu => {
        if (!ssMenu.desktopOnly) {
          if (ssMenu.text) {
            newList.push(ssMenu);
          } else {
            newList.push({
              text: ssMenu.text,
              subMenu: ssMenu.subMenu
            });
          }
        }
      });
      return;
    }
    newList.push(item);
  });
  return newList;
};

export const MobileMenuList = ({ data = [] }) => {
  const [openKeys, setOpenKeys] = React.useState([]);
  const [currentPath, setCurrentPath] = React.useState('');

  React.useEffect(() => {
    setCurrentPath(window.location.pathname);
  }, []);

  React.useEffect(() => {
    const list = normalizeListItem(data);
    list.forEach(item => {
      const { subMenu } = item;
      if (subMenu?.length > 0) {
        const someLinkActive = subMenu.some(g =>
          checkIfLinkOfCurrentPageIsActiveByPath(currentPath, g.link)
        );
        if (someLinkActive) {
          setOpenKeys(item.text);
        }

        subMenu?.forEach(k => {
          const { subMenu } = k;
          if (subMenu?.length > 0) {
            const someLinkActive = subMenu.some(g =>
              checkIfLinkOfCurrentPageIsActiveByPath(currentPath, g.link)
            );
            if (someLinkActive) {
              setOpenKeys(item.text);
            }
          }
        });
      }
    });
  }, [currentPath, data]);

  const renderItem = React.useCallback(item => {
    return (
      <NavMenuItem
        key={item.text + item.link}
        text={item.text}
        link={item.link}
        external={item.external}
      />
    );
  }, []);

  return (
    <Main className="mobile-menu-list">
      {normalizeListItem(data).map((item, index) => {
        const { text, link, subMenu } = item;
        const isLinkActive = checkIfLinkOfCurrentPageIsActiveByPath(
          currentPath,
          link
        );
        if (subMenu && subMenu.length > 0) {
          const someLinkActive = subMenu?.some?.(g =>
            checkIfLinkOfCurrentPageIsActiveByPath(currentPath, g.link)
          );
          const someSubLinkActive = subMenu?.some?.(g =>
            g.subMenu?.some(z => {
              return checkIfLinkOfCurrentPageIsActiveByPath(
                currentPath,
                z.link
              );
            })
          );

          return (
            <div
              key={text}
              onClick={() => {
                setOpenKeys(o => {
                  if (o === text) {
                    return [];
                  }
                  if (o.includes(text)) {
                    return o.filter(item => item !== text);
                  }
                  return [...o, text];
                });
              }}
              className={cl('sub-menu-list', {
                expanded: openKeys.includes(text),
                active: isLinkActive || someLinkActive || someSubLinkActive
              })}
            >
              <div className="sub-menu">
                {renderItem(item)}

                <div className="icon-arrow">
                  <OutlineArrowChevronRight width={24} />
                </div>
              </div>
              <div className="sub-menu-list-items">
                <div className="group-wrapper">
                  {subMenu.map((group, gIndex) => {
                    return (
                      <div
                        key={gIndex}
                        className={cl('group-item', {
                          active: checkIfLinkOfCurrentPageIsActiveByPath(
                            currentPath,
                            group.link,
                            true
                          )
                        })}
                      >
                        {renderItem(group)}
                        {group.subMenu?.length > 0 && (
                          <div
                            style={{
                              marginTop: '12px'
                            }}
                          >
                            {group?.subMenu?.map((k, idx) => {
                              return (
                                <div
                                  key={idx}
                                  className={cl(
                                    'group-item',
                                    'group-item-children',
                                    {
                                      active:
                                        checkIfLinkOfCurrentPageIsActiveByPath(
                                          currentPath,
                                          k.link,
                                          true
                                        )
                                    }
                                  )}
                                >
                                  <div className="group-vertical-line" />

                                  {renderItem(k)}
                                </div>
                              );
                            })}
                          </div>
                        )}
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          );
        }
        return (
          <div
            key={index}
            className={cl('nav-list', {
              active: isLinkActive
            })}
          >
            {renderItem(item)}
          </div>
        );
      })}
    </Main>
  );
};
