import React from 'react';
import styled from 'styled-components';
import { Modal } from 'react-responsive-modal';
import { v4 as uuidv4 } from 'uuid';

import { Typography } from 'components/DesignSystemV2/Typography';
import { colorsV2 } from 'style/colors-v2';
import IconEssentialClose from 'components/Icon/SVGIcons/IconEssentialClose';
import PhoneItem from './components/form-item/PhoneItem';
import AddressItem from './components/form-item/AddressItem';
import { ClassItem } from './components/form-item/ClassItem';
import { GoalItem } from './components/form-item/GoalItem';
import { RelationshipItem } from './components/form-item/RelationshipItem';
import { AlertSimple } from 'shared/AlertSimple/AlertSimple';
import { IconCourseInfoFill } from 'components/Icon/SVGIcons';
import Button from '@dolstaff/shared/es/Button';
import { scrollBarStylesGeneral } from 'style/css-style';
import { fromScreen } from 'utils/media-query/responsive.util';
import useWindowSize from 'utils/hooks/useWindowSize';
import axios from 'axios';
import toast, { Toaster } from 'react-hot-toast';
import { navigate } from 'gatsby';
import LastNameItem from './components/form-item/LastNameItem';
import FirstNameItem from './components/form-item/FirstNameItem';
import { UserPixelTracking } from 'utils/userPixelTracking';

const header = {
  'Content-Type': 'application/json'
};

export const ClassOptions = [
  { key: 'GRADE_5', value: 'Lớp 5' },
  { key: 'GRADE_6', value: 'Lớp 6' },
  { key: 'GRADE_7', value: 'Lớp 7' },
  { key: 'GRADE_8', value: 'Lớp 8' },
  { key: 'GRADE_9', value: 'Lớp 9' }
];

export const RelationshipOptions = [
  { key: 'FATHER', value: 'Bố' },
  { key: 'MOTHER', value: 'Mẹ' },
  { key: 'GRANDPARENTS', value: 'Ông/Bà' },
  { key: 'OTHER', value: 'Khác' }
];

export const goalOptions = [
  { key: 'IELTS5560', value: 'IELTS 5.5 - 6.0' },
  { key: 'IELTS6570', value: 'IELTS 6.5 - 7.0' },
  { key: 'IELTS7', value: 'IELTS 7.0+' }
];

const ModalHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  padding: 24px 32px;
  border-bottom: 1px solid ${colorsV2.neutral20};

  .icon {
    line-height: 0;

    svg {
      width: 24px;
      height: 24px;
      cursor: pointer;
    }
  }
`;
const ModalWrapper = styled.div`
  width: 100%;
  ${fromScreen(776)} {
    width: 600px;
  }
`;

const FormGroup = styled.form`
  overflow-y: auto;
  ${scrollBarStylesGeneral()};

  display: grid;
  gap: 24px;
  padding: 24px 32px;
  .high-light {
    font-weight: 700;
  }
`;

const ModalFooter = styled.div`
  border-top: 1px solid ${colorsV2.neutral20};
  padding: 24px 32px;
  text-align: end;
  .btn-action {
    border-radius: 12px;
  }
`;

const ParentGroup = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 24px;
`;

const RegisterForm = ({ isOpen, closeModal }) => {
  const [submited, updateSubmited] = React.useState(false);
  const windowSize = useWindowSize();
  const [values, updateValues] = React.useState({
    firstName: '',
    lastName: '',
    phoneNumber: '',
    grade: '',
    goal: '',
    relationship: '',
    address: ''
  });

  const [errors, updateErrors] = React.useState({
    errPhone: ''
  });

  const resetValues = () => {
    updateValues({
      firstName: '',
      lastName: '',
      phoneNumber: '',
      grade: '',
      goal: '',
      relationship: '',
      address: ''
    });
  };

  const handleSubmit = async () => {
    updateSubmited(true);
    try {
      const isValidPhone = /^0{1}[0-9]{9,10}$/gm.test(values.phoneNumber);
      const isBrowser = () => typeof window !== 'undefined';

      if (!isValidPhone) {
        updateErrors(prevErr => ({
          ...prevErr,
          errPhone: 'Số điện thoại chưa chính xác'
        }));
        updateSubmited(false);
        return;
      }
      updateErrors(prevErr => ({
        ...prevErr,
        errPhone: ''
      }));
      await axios.post(
        `${process.env.PUBLIC_ONLINE_TEST_V2_SERVICE_API_HOSTNAME}/registration`,
        {
          firstName: values.firstName,
          lastName: values.lastName,
          phoneNumber: values.phoneNumber,
          grade: values.grade,
          goal: values.goal,
          relationship: values.relationship,
          address: values.address,
          userAgent: (isBrowser() && window?.location?.href) || ''
        },
        header
      );
      const event_id = uuidv4();
      UserPixelTracking.submitFromSDK(event_id);
      UserPixelTracking.submitForm(event_id, values);
      toast.success('Đăng ký ghi danh sớm thành công!', {
        id: 'errorId',
        duration: 2000,
        position: 'top-center'
      });
      navigate('/thank-you-junior-registration');
    } catch (error) {
      updateSubmited(false);
      toast.error('Đã có lỗi xảy ra, vui lòng thử lại sau.', {
        id: 'errorId',
        duration: 2000,
        position: 'top-center'
      });
    }
  };

  return (
    <Modal
      blockScroll={false}
      showCloseIcon={false}
      closeOnOverlayClick={false}
      closeOnEsc={false}
      onClose={closeModal}
      open={isOpen}
      styles={{
        overlay: {
          padding: '60px 16px'
        },
        modal: {
          maxHeight: '100%',
          maxWidth: '100%',
          borderRadius: 20,
          padding: 0
        }
      }}
    >
      <ModalWrapper>
        <ModalHeader>
          <Typography variant="semi-bold/20-28">
            Đăng ký ghi danh sớm
          </Typography>
          <div
            onClick={() => {
              closeModal?.();
              resetValues();
            }}
            className="icon"
          >
            <IconEssentialClose color={colorsV2.neutral100} />
          </div>
        </ModalHeader>
        <FormGroup
          style={{
            height: `${
              windowSize.innerHeight ? windowSize.innerHeight - 300 : 350
            }px`,
            maxHeight: '692px'
          }}
        >
          <ParentGroup>
            <FirstNameItem
              value={values.firstName}
              onChange={firstName =>
                updateValues(prevForm => ({ ...prevForm, firstName }))
              }
            />
            <LastNameItem
              value={values.lastName}
              onChange={lastName =>
                updateValues(prevForm => ({ ...prevForm, lastName }))
              }
            />
          </ParentGroup>

          <PhoneItem
            value={values.phoneNumber}
            onChange={phoneNumber =>
              updateValues(prevForm => ({ ...prevForm, phoneNumber }))
            }
            errorMessage={errors.errPhone}
          />
          <ClassItem
            options={ClassOptions}
            value={values.grade}
            onChange={grade =>
              updateValues(prevForm => ({ ...prevForm, grade }))
            }
          />
          <GoalItem
            options={goalOptions}
            value={values.goal}
            onChange={goal => updateValues(prevForm => ({ ...prevForm, goal }))}
          />
          <RelationshipItem
            options={RelationshipOptions}
            value={values.relationship}
            onChange={relationship =>
              updateValues(prevForm => ({ ...prevForm, relationship }))
            }
          />
          <AddressItem
            value={values.address}
            onChange={address =>
              updateValues(prevForm => ({ ...prevForm, address }))
            }
          />
          <AlertSimple
            type="info"
            icon={
              <IconCourseInfoFill
                width={20}
                height={20}
                color={colorsV2.blue100}
              />
            }
            text={
              <Typography variant="regular/14-20" color={colorsV2.blue100}>
                <span className="high-light">Cam Kết:</span> Mọi thông tin là để
                phục vụ tư vấn và ưu tiên ghi danh sớm, tuyệt đối được bảo mật
              </Typography>
            }
          />
        </FormGroup>
        <ModalFooter>
          <Button
            size="large"
            className="btn-action"
            type="primary"
            marginBottom="0"
            disabled={
              !values.address ||
              !values.goal ||
              !values.grade ||
              !values.phoneNumber ||
              !values.relationship ||
              !values.firstName ||
              !values.lastName
            }
            onClick={submited ? () => null : handleSubmit}
          >
            <Typography variant="semi-bold/14-20" color={colorsV2.white100}>
              Xác nhận
            </Typography>
          </Button>
        </ModalFooter>
      </ModalWrapper>
      <Toaster />
    </Modal>
  );
};

export default RegisterForm;
