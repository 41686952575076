import cl from 'classnames';
import * as React from 'react';
import styled from 'styled-components';

import { Link } from './Link';
import OutlineArrowChevronRight from '@dolstaff/shared/es/icons/OutlineArrowChevronRight';
import { colorsV2 } from 'style/colors-v2';
import { Typography } from 'components/DesignSystemV2/Typography';

const Main = styled.div`
  position: relative;
  display: grid;
  align-items: center;
  gap: 8px;
  height: 100%;
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  cursor: pointer;
  color: #242938;
  .menu-item {
    height: 100%;
    display: flex;
    align-items: center;
  }
  .disabled {
    color: ${colorsV2.neutral80};
  }
  .tool-tip {
    display: none;
    position: absolute;
    padding: 8px 12px;
    width: 127px;
    right: -135px;
    z-index: 10000;
    background: ${colorsV2.neutral180};
    border-radius: 12px;
  }
  &:hover {
    .disabled {
      color: ${colorsV2.neutral120};
    }
    .tool-tip {
      display: block;
    }
  }
`;

export const NavMenuItem = ({
  className,
  text,
  link,
  external,
  withIcon = false,
  isDisable = false
}) => {
  if (external) {
    return (
      <Main className={cl('nav-menu-item', { 'empty-link': !link })}>
        {link ? (
          <a
            key={text + link}
            href={`${link}`}
            target="_blank"
            rel="noopener noreferrer noffolow"
            className={cl('menu-item', className)}
          >
            {text}
            {withIcon && (
              <OutlineArrowChevronRight
                width={24}
                color={colorsV2.neutral100}
              />
            )}
          </a>
        ) : (
          <div className={cl('menu-item', className)}>
            {text}
            {withIcon && (
              <OutlineArrowChevronRight
                width={24}
                color={colorsV2.neutral100}
              />
            )}
          </div>
        )}
      </Main>
    );
  }

  return (
    <Main className={cl('nav-menu-item', { 'empty-link': !link })}>
      {link ? (
        <Link
          className={cl('menu-item', className, { disabled: isDisable })}
          key={text + link}
          to={link}
        >
          {text}
          {withIcon && (
            <OutlineArrowChevronRight width={24} color={colorsV2.neutral100} />
          )}
          {isDisable && (
            <div className="tool-tip">
              <Typography variant="medium/14-20" color={colorsV2.white100}>
                Coming soon...
              </Typography>
            </div>
          )}
        </Link>
      ) : (
        <div className={cl('menu-item', className, { disabled: isDisable })}>
          {text}
          {withIcon && (
            <OutlineArrowChevronRight width={24} color={colorsV2.neutral100} />
          )}
          {isDisable && (
            <div className="tool-tip">
              <Typography variant="medium/14-20" color={colorsV2.white100}>
                Coming soon...
              </Typography>
            </div>
          )}
        </div>
      )}
    </Main>
  );
};
