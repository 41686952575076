import * as React from 'react';

function IconsUserUserTeacher(props) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10 5C8.89543 5 8 5.89543 8 7C8 8.10457 8.89543 9 10 9C11.1046 9 12 8.10457 12 7C12 5.89543 11.1046 5 10 5ZM6 7C6 4.79086 7.79086 3 10 3C12.2091 3 14 4.79086 14 7C14 9.20914 12.2091 11 10 11C7.79086 11 6 9.20914 6 7ZM11.4296 13.1125C8.40455 12.6488 5.15819 13.6209 2.61821 15.9797C2.20639 16.3622 2 16.8907 2 17.4146V20.0009C2 20.5532 2.44772 21.0009 3 21.0009H11.5286C11.0119 20.4238 10.6053 19.7462 10.3417 19.0009H4V17.426C5.87863 15.691 8.15947 14.8972 10.3377 15.0106C10.5841 14.3091 10.9572 13.6673 11.4296 13.1125ZM18.5001 12.902C19.6212 12.3583 20.8476 12.0514 22.0998 12.0029C22.586 11.9755 23.0664 12.1448 23.4273 12.4778C23.7921 12.8144 24.0023 13.289 24.0001 13.7898V19.1876C23.9872 20.1505 23.2179 20.9072 22.2821 20.9534C22.2747 20.9538 22.2673 20.954 22.2599 20.9542C22.255 20.9544 22.2501 20.9545 22.2452 20.9545C22.1656 20.9555 22.0859 20.9579 22.0065 20.9618C20.9473 21.0136 19.9175 21.3222 19.0102 21.8602C18.8529 21.9534 18.6765 22 18.5001 22C18.3236 22 18.1472 21.9534 17.99 21.8602C17.0147 21.2818 15.8977 20.9686 14.7549 20.9545C14.7426 20.9544 14.7303 20.954 14.718 20.9534C13.7823 20.9072 13.0129 20.1505 13 19.1876V13.7898C12.9978 13.289 13.208 12.8144 13.5728 12.4778C13.9337 12.1448 14.414 11.9755 14.9002 12.0029C16.1524 12.0514 17.3789 12.3583 18.5001 12.902ZM22 14.0105V18.9602C21.1444 18.9929 20.3015 19.1539 19.5 19.4355V14.6415C20.284 14.2805 21.1319 14.0659 22 14.0105ZM15.0002 18.9602C15.8557 18.9929 16.6985 19.1539 17.5 19.4355V14.6414C16.716 14.2805 15.8682 14.0659 15.0002 14.0105V18.9602Z"
        fill="currentColor"
      />
    </svg>
  );
}

export default React.memo(IconsUserUserTeacher);
