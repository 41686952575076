import * as React from 'react';

function IconsMinus(props) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5 11C4.44772 11 4 11.4477 4 12C4 12.5523 4.44772 13 5 13C13.4009 13 9.96758 13 19 13C19.5523 13 20 12.5523 20 12C20 11.4477 19.5523 11 19 11C10.5991 11 14.0324 11 5 11Z"
        fill="currentColor"
      />
    </svg>
  );
}

export default React.memo(IconsMinus);
