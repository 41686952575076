import * as React from 'react';

/* eslint-disable react/prop-types */
import { withHomePageSection } from 'components/withHomePageSection';
import { colorsV2 } from 'style/colors-v2';
import { Difference } from 'shared/CourseLandingV3/Difference';

const IeltsOnlineDifferencesBase = ({ data }) => {
  return (
    <Difference
      data={data}
      headerTitle="6 điểm khác biệt của khóa Online tại DOL"
    />
  );
};

export const IeltsOnlineDifferences = withHomePageSection(
  () => {
    const data = [
      {
        imageUrl:
          'https://gqefcpylonobj.vcdn.cloud/directus-upload/d0c36d06-0a3f-4820-b282-ca9bd895d27b.png',
        imageAlt: 'ielts-online-difference-01',
        title: 'Linearthinking học 1 được 4',
        description:
          'Linearthinking được phát minh bởi, hệ phương pháp học Tiếng Anh Tư Duy, không những giúp học viên đạt điểm IELTS cao nhanh chóng mà còn ứng dụng được trong giao tiếp, học đại học, và phát triển critical thinking.',
        actionText: 'Xem chứng nhận'
      },
      {
        imageUrl:
          'https://gqefcpylonobj.vcdn.cloud/directus-upload/5eec356c-71d7-4d13-adbe-bc0be7fa79b2.png',
        imageAlt: 'ielts-online-difference-02',
        title: 'Tư duy thiết kế bài giảng Online khác biệt',
        description:
          'DOL biết khi học Online rất dễ bị mất tập trung, nên bài giảng DOL được chia nhỏ thành từng phần dưới 5 phút, cuối mỗi phần đều có tóm tắt và tương tác để chắc học viên hiểu bài. Cách thiết kế bài giảng này tránh được hiệu ứng dây chuyền khi học viên lơ đãng chỉ trong 1 vài phút.',
        actionText: 'Xem bài giảng thực tế'
      },
      {
        imageUrl:
          'https://gqefcpylonobj.vcdn.cloud/directus-upload/0d36e711-3bb5-419c-90f1-daaf193eb171.png',
        imageAlt: 'ielts-online-difference-03',
        title: 'Tương tác liên tục',
        description:
          'DOL biết khi học Online rất dễ bị mất tập trung, nên bài giảng DOL được chia nhỏ thành từng phần dưới 5 phút, cuối mỗi phần đều có tóm tắt và tương tác để chắc học viên hiểu bài. Cách thiết kế bài giảng này tránh được hiệu ứng dây chuyền khi học viên lơ đãng chỉ trong 1 vài phút.',
        actionText: 'Xem bài giảng thực tế'
      },
      {
        imageUrl:
          'https://gqefcpylonobj.vcdn.cloud/directus-upload/1c5ed3f8-1d58-4519-a712-e8d13cf82368.png',
        imageAlt: 'ielts-online-difference-04',
        title: 'Sửa bài Writing & Speaking về nhà qua App',
        description:
          'DOL có hệ thống app riêng, giúp học viên làm assignment Writing và được giáo viên chấm rất kỹ, kèm theo sửa lỗi và nhận xét bằng giọng nói.',
        actionText: 'Xem video sửa bài mẫu'
      },
      {
        imageUrl:
          'https://gqefcpylonobj.vcdn.cloud/directus-upload/8cf248a1-fa3a-466e-8380-f49026f2497a.png',
        imageAlt: 'ielts-online-difference-05',
        title: 'Luyện tập Speaking trên lớp không còn là nỗi lo',
        description:
          'DOL chia nhóm học viên dùng breakout rooms của Zoom và có thêm trợ giảng (TA) vào các nhóm để sửa bài cho học viên. Các bạn yên tâm là mình sẽ được luyện tập Speaking và được sửa y chang offline.',
        actionText: 'Xem bài giảng thực tế'
      },
      {
        imageUrl:
          'https://gqefcpylonobj.vcdn.cloud/directus-upload/4883ab3b-804d-4197-96a7-3954fb230c53.png',
        imageAlt: 'ielts-online-difference-06',
        title: 'Kho Reading, Listening luyện tập thoải mái',
        description:
          'DOL có hệ thống app với kho đề IELTS Reading, Listening khủng, kèm giải thích chi tiết và từ vựng được cá nhân hoá theo câu sai của từng bạn. Các bạn tha hồ tự luyện tập thêm ở nhà.',
        actionText: 'Xem trải nghiệm app'
      }
    ];
    return <IeltsOnlineDifferencesBase data={data} />;
  },
  {
    style: {
      backgroundColor: colorsV2.white100
    }
  },
  {}
);
