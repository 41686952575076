import Cookies from 'js-cookie';

import {
  trackingUserClickEvent,
  trackingUserPageViewEvent,
  trackingUserCompleteRegistrationEvent
} from '../apis/userPixelTracking.js';
import { EVENTS, PLATFORMS } from 'constant/userPixelTracking';
import { getUserIPV6 } from 'utils/userIP';

const isBrowser = () => typeof window !== 'undefined';
class UserPixelTrackingService {
  pixel = 'dolenglish';
  constructor() {
    this.url = isBrowser() && window?.location?.hostname;
    this.userAgent = isBrowser() && window?.navigator?.userAgent;
  }

  getUrl() {
    return this.url;
  }

  getUserAgent() {
    return this.userAgent;
  }

  submitFromSDK = async event_id => {
    return (
      isBrowser() &&
      window?.ttq?.track(EVENTS.CompleteRegistration, {
        event_id: event_id
      })
    );
  };

  submitForm = async (event_id, user_data) => {
    try {
      const ip = await getUserIPV6();
      const payload = {
        platforms: [PLATFORMS.TIKTOK, PLATFORMS.FACEBOOK],
        event_id: event_id,
        event: EVENTS.CompleteRegistration,
        pixel: this.pixel,
        user_data: {
          url: this.url,
          client_ip_address: ip,
          client_user_agent: this.userAgent,
          fbc: Cookies.get('_fbc') || '',
          fbp: Cookies.get('_fbp') || '',
          ttp: Cookies.get('_ttp') || '',
          em: '', // email hash 256
          phone_number: user_data.phoneNumber,
          first_name: user_data.firstName,
          last_name: user_data.lastName
        }
      };
      await trackingUserCompleteRegistrationEvent(payload);
    } catch (error) {
      console.error('Error tracking user click event:', error);
    }
  };

  clickEventPixelSDK = async event_id => {
    // const ip = await getUserIPV6();
    return (
      isBrowser() &&
      window?.ttq?.track(EVENTS.CONTACT, {
        event_id: event_id
      })
    );
  };

  clickEvent = async event_id => {
    try {
      const ip = await getUserIPV6();
      const payload = {
        platforms: [PLATFORMS.TIKTOK, PLATFORMS.FACEBOOK],
        event_id: event_id,
        event: EVENTS.CONTACT,
        pixel: this.pixel,
        user_data: {
          url: this.url,
          client_ip_address: ip,
          client_user_agent: this.userAgent,
          fbc: Cookies.get('_fbc') || '',
          fbp: Cookies.get('_fbp') || '',
          ttp: Cookies.get('_ttp') || '',
          em: '' // email hash 256
        }
      };
      await trackingUserClickEvent(payload);
    } catch (error) {
      console.error('Error tracking user click event:', error);
    }
  };

  pageViewEventPixelSDK = async event_id => {
    // const ip = await getUserIPV6();
    return (
      isBrowser() &&
      window?.ttq?.track(EVENTS.PageView, {
        event_id: event_id
      })
    );
  };

  pageViewEvent = async event_id => {
    try {
      const ip = await getUserIPV6();
      const payload = {
        platforms: [PLATFORMS.TIKTOK, PLATFORMS.FACEBOOK],
        event_id: event_id,
        event: EVENTS.PageView,
        pixel: this.pixel,
        user_data: {
          url: this.url,
          client_ip_address: ip,
          client_user_agent: this.userAgent,
          fbc: Cookies.get('_fbc') || '',
          fbp: Cookies.get('_fbp') || '',
          ttp: Cookies.get('_ttp') || '',
          em: '' // email hash 256
        }
      };
      await trackingUserPageViewEvent(payload);
    } catch (error) {
      console.error('Error tracking user view content event:', error);
    }
  };
}

export const UserPixelTracking = new UserPixelTrackingService();
