import * as React from 'react';

const IconArrowDown = props => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.27124 8.85484C6.93045 8.53929 6.40419 8.53929 6.0634 8.85484L6.0384 8.87799C5.65844 9.2298 5.65844 9.83064 6.0384 10.1825L11.3968 15.1439C11.7376 15.4594 12.2638 15.4594 12.6046 15.1439L17.9629 10.1825C18.3429 9.83066 18.3429 9.22983 17.9629 8.87802L17.9379 8.85487C17.5971 8.53932 17.0709 8.53932 16.7301 8.85487L12.0007 13.2339L7.27124 8.85484Z"
      fill="currentColor"
    />
  </svg>
);

export default IconArrowDown;
