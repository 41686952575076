/* eslint-disable react/prop-types */
import * as React from 'react';
import styled from 'styled-components';
import ExpandCollapsePanel from 'shared/SchedulePage/ExpandCollapsePanel';
import { colorsV2 } from 'style/colors-v2';
import { fromScreen } from 'utils/media-query/responsive.util';

const CourseFAQWrapper = styled.div`
  display: grid;
  grid-row-gap: 32px;
  width: 100%;
  .faq-section {
    color: ${colorsV2.black100};
    .expand-collapse-panel {
      border: none;
      border-bottom: 1px solid ${colorsV2.neutral20};
      border-radius: 0;
      background: white;
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
  .txt-description {
    font-size: 20px;
    font-weight: 400;
    line-height: 32px;
    letter-spacing: 0px;
    text-align: left;
  }
  ${fromScreen(1144)} {
    width: 712px;
    margin: auto;
  }
`;

const FAQGroup = styled.div`
  display: grid;
  grid-row-gap: 32px;

  .expanded {
    .panel-header {
      padding: 0 0 12px !important;
    }
    .panel-content {
      padding: 0 36px 32px 0 !important;
    }
  }
  .panel-header {
    padding: 0 0 32px !important;
  }
  .panel-content {
    padding: 0 36px 0 0 !important;
    word-break: break-word;
  }
`;

export const CourseFAQ = ({ faqs }) => {
  const [expandIndex, setExpandIndex] = React.useState(1);
  return (
    <CourseFAQWrapper>
      <FAQGroup>
        {faqs?.map((faq, idx) => {
          const id = idx + 1;
          const { question, content } = faq;
          return (
            <div className="faq-section" key={id}>
              <ExpandCollapsePanel
                onExpandCollapseClick={() => {
                  setExpandIndex(prevExpandIndex => {
                    if (prevExpandIndex === id) return -1;
                    return id;
                  });
                }}
                expanded={expandIndex === id}
                title={question}
                isPlusIcon={true}
              >
                <p
                  style={{ whiteSpace: 'pre-wrap' }}
                  dangerouslySetInnerHTML={{
                    __html: content
                  }}
                />
              </ExpandCollapsePanel>
            </div>
          );
        })}
      </FAQGroup>
    </CourseFAQWrapper>
  );
};
