import * as React from 'react';
import styled from 'styled-components/macro';
import { CourseHeaderLayout } from './components/CourseHeaderLayout';
import { MainHeaderContent } from './components/MainHeaderContent';
import { LazyImage } from 'components/LazyImage';
import { fromScreen } from 'utils/media-query/responsive.util';

const Main = styled.div`
  position: relative;
  .course-background {
    position: absolute;
    top: 45%;
    left: -80%;
    width: 1202px;
    height: 100%;
  }
  ${fromScreen(458)} {
    .course-background {
      top: 45%;
      left: -80%;
    }
  }
  ${fromScreen(776)} {
    .course-background {
      top: 40%;
      left: -40%;
    }
  }
  ${fromScreen(1144)} {
    .course-background {
      top: -80px;
      left: 10%;
    }
  }
`;

const RightSide = styled.div`
  position: relative;
  .course-with-laptop {
    position: absolute;
    width: 444px;
    left: -10%;
  }
  .rectangle-top {
    position: absolute;
    width: 77px;
    right: -6%;
    top: -6%;
    transform: rotate(3.55deg);
  }
  .rectangle-left {
    position: absolute;
    width: 52px;
    left: -15px;
    bottom: 160px;
    transform: rotate(-16.93deg);
  }
  .rectangle-right {
    position: absolute;
    width: 62px;
    right: -5%;
    bottom: 100px;
    transform: rotate(11.02deg);
  }
  ${fromScreen(458)} {
    .course-with-laptop {
      left: -5%;
    }
    .rectangle-top {
      right: 1%;
      top: -6%;
    }
    .rectangle-left {
      left: -10px;
      bottom: 155px;
    }
    .rectangle-right {
      right: -5%;
      bottom: 110px;
    }
  }
  ${fromScreen(776)} {
    .course-with-laptop {
      width: 490px;
      left: 15%;
    }
    .rectangle-top {
      right: 17%;
      top: -6%;
    }
    .rectangle-left {
      left: 130px;
      bottom: 130px;
    }
    .rectangle-right {
      right: 15%;
      bottom: 50px;
    }
  }
  ${fromScreen(1144)} {
    .course-with-laptop {
      left: 0;
    }
    .rectangle-top {
      right: -14%;
      top: -6%;
    }
    .rectangle-left {
      left: 44px;
      bottom: 100px;
    }
    .rectangle-right {
      right: -10%;
      bottom: 0px;
    }
  }
`;

export const CourseHeaderIeltsOnline = ({ data }) => {
  const { tagText, title, description, list = [] } = data || {};

  return (
    <Main>
      <CourseHeaderLayout
        className="ielts-online"
        mainContent={
          <MainHeaderContent
            tagText={tagText}
            title={title}
            description={description}
            list={list}
          />
        }
        videoContent={
          <RightSide>
            <LazyImage
              className="course-with-laptop"
              src="https://gqefcpylonobj.vcdn.cloud/directus-upload/01c7548f-7cdd-41d2-955f-518a3eb83434.png"
              alt="course-with-laptop"
            />
            <LazyImage
              className="rectangle-top"
              src="https://gqefcpylonobj.vcdn.cloud/directus-upload/878ef53b-680b-4494-b910-a5a29769e4be.png"
              alt="rectangle-top"
            />
            <LazyImage
              className="rectangle-left"
              src="https://gqefcpylonobj.vcdn.cloud/directus-upload/e3619f73-9f7c-40aa-999a-2f39247c68a1.png"
              alt="rectangle-left"
            />
            <LazyImage
              className="rectangle-right"
              src="https://gqefcpylonobj.vcdn.cloud/directus-upload/612961c5-2a59-45cc-bd7f-c02ea89875e7.png"
              alt="rectangle-right"
            />
          </RightSide>
        }
      />
      <LazyImage
        className="course-background"
        src="https://gqefcpylonobj.vcdn.cloud/directus-upload/7fb9cab3-6927-44ab-a1e9-94e2335ba7c9.png"
        alt="course-background"
      />
    </Main>
  );
};
