import * as React from 'react';
import styled from 'styled-components/macro';
import { Typography } from 'components/DesignSystemV2/Typography';
import { colorsV2 } from 'style/colors-v2';
import { LazyImage } from 'components/LazyImage';
import { fromScreen } from 'utils/media-query/responsive.util';
import { CourseTextLink } from './components/CourseTextLink';

const Main = styled.div`
  display: grid;
  gap: 48px;
  .header-title {
    text-align: center;
    width: 100%;
    margin: auto;
  }
  ${fromScreen(776)} {
    .header-title {
      width: 390px;
    }
  }
  ${fromScreen(1144)} {
    .header-title {
      width: 490px;
    }
  }
`;
const MainContent = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  gap: 24px;
  row-gap: 48px;
  ${fromScreen(776)} {
    grid-template-columns: repeat(2, 1fr);
  }
  ${fromScreen(1144)} {
    grid-template-columns: repeat(3, 1fr);
  }
`;

const Item = styled.div`
  .image {
    img {
      border-radius: 16px;
    }
  }
`;
const DescriptionWrapper = styled.div`
  display: grid;
  gap: 16px;
  padding: 16px;
`;

export const Difference = ({ headerTitle = '', data }) => {
  return (
    <Main>
      <Typography
        variant="bold/24-32"
        tabletVariant="bold/32-40"
        desktopVariant="bold/40-48"
        v3
        as="h2"
        color={colorsV2.neutral180}
        className="header-title"
      >
        {headerTitle}
      </Typography>
      <MainContent>
        {data?.map((eachItem, idx) => {
          const { title, description, actionText, imageUrl, imageAlt } =
            eachItem || {};
          return (
            <Item key={idx}>
              <LazyImage className="image" src={imageUrl} alt={imageAlt} />
              <DescriptionWrapper>
                <Typography
                  variant="semi-bold/20-28"
                  color={colorsV2.neutral180}
                >
                  {title}
                </Typography>
                <Typography variant="medium/16-24" color={colorsV2.neutral120}>
                  {description}
                </Typography>
                <CourseTextLink text={actionText} variant="medium/16-24" />
              </DescriptionWrapper>
            </Item>
          );
        })}
      </MainContent>
    </Main>
  );
};
