import { css } from 'styled-components';

export const innerBorder = (style, position, inheritColor = false) => {
  const borderProperty =
    position && position !== 'all' ? `border-${position}` : 'border';

  return css`
    position: relative;

    &:after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      border-radius: inherit;
      ${borderProperty}: ${style || 'none'};
      ${inheritColor ? 'border-color: inherit;' : undefined}
      pointer-events: none;
      user-select: none;
    }
  `;
};

export const horizontalSpace = (spaceValue = 8) => {
  const space = typeof spaceValue === 'number' ? `${spaceValue}px` : spaceValue;

  return css`
    > * {
      &:not(:last-child) {
        margin-right: ${space} !important;
      }
    }
  `;
};

export const verticalSpace = (spaceValue = 8) => {
  const space = typeof spaceValue === 'number' ? `${spaceValue}px` : spaceValue;

  return css`
    > * {
      &:not(:last-child) {
        margin-bottom: ${space} !important;
      }
    }
  `;
};

export const flexGap = (gapValue = 8, flexDirection = 'row') => {
  if (Array.isArray(gapValue)) {
    const rowGap =
      typeof gapValue[0] === 'number' ? `${gapValue[0]}px` : gapValue[0];
    const columnGap =
      typeof gapValue[1] === 'number' ? `${gapValue[1]}px` : gapValue[1];

    return css`
      margin-bottom: -${columnGap};
      margin-right: -${rowGap};

      > * {
        margin-bottom: ${columnGap} !important;
        margin-right: ${rowGap} !important;
      }
    `;
  }

  const gap = typeof gapValue === 'number' ? `${gapValue}px` : gapValue;

  if (flexDirection === 'column') {
    return verticalSpace(gap);
  }

  return horizontalSpace(gap);
};

export const scrollBarStyles = (styles = {}) => {
  const {
    size = 12,
    trackColor = '#FFFFFF',
    thumbColor = '#D2D3D9',
    thumbSize = 4
  } = styles;

  return css`
    &::-webkit-scrollbar {
      width: ${size}px;
      height: ${size}px;
    }
    &::-webkit-scrollbar-track {
      background-color: ${trackColor};
    }
    &::-webkit-scrollbar-thumb {
      background-color: ${thumbColor};
      border: ${(size - thumbSize) / 2}px solid ${trackColor};
    }
  `;
};

export const scrollBarStylesGeneral = (thumbColor = '#D2D8DF') => {
  return scrollBarStyles({
    size: 12,
    trackColor: '#FFFFFF',
    thumbColor,
    thumbSize: 4
  });
};
