import * as React from 'react';
import { colorsV2 } from 'style/colors-v2';
import { shadows } from 'style/shadows';
import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  flex-direction: column;

  padding: 8px 0px;
  background-color: ${colorsV2.white100};
  border: 1px solid ${colorsV2.gray20};
  box-shadow: ${shadows.zIndex5};
  border-radius: 8px;
`;

export const SelectOptionList = props => {
  const { children } = props;

  return <Container className="select-option-list">{children}</Container>;
};
