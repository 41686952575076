import * as React from 'react';

/* eslint-disable react/prop-types */
import { withHomePageSection } from 'components/withHomePageSection';
import { colorsV2 } from 'style/colors-v2';
import { WhatYourProblem } from 'shared/CourseLandingV3/WhatYourProblem';
import { graphql, useStaticQuery } from 'gatsby';

const IeltsCapTocWhatYourProblemBase = ({ data }) => {
  return (
    <WhatYourProblem
      data={data}
      title="Bạn có đang gặp phải những vấn đề sau?"
    />
  );
};

export const IeltsCapTocWhatYourProblem = withHomePageSection(
  () => {
    const data = useStaticQuery(graphql`
      {
        gcms {
          jsonContanier(where: { name: "IeltsLanding_IeltsCapToc_Problem" }) {
            content
          }
        }
      }
    `);
    return (
      <IeltsCapTocWhatYourProblemBase data={data.gcms.jsonContanier.content} />
    );
  },
  {
    style: {
      backgroundColor: colorsV2.black100
    }
  },
  {}
);
