import * as React from 'react';
import MainLayout from 'components/Layout/MainLayout';
import HeaderSchedulePage from 'shared/Header/HeaderSchedulePage';
import MySEO from 'shared/MySEO';
import Layout from 'shared/AnotherCourses/Layout';
import { HeaderWithPromotion } from 'shared/AnotherCourses/component/HeaderPage';
import { useBreadcrumbSchema } from 'shared/AnotherCourses/hooks/useBreadcrumbSchema';

const ListCourse = ({ location }) => {
  const breadCrumbList = useBreadcrumbSchema({
    slug: 'gmat-gre',
    heading: 'GMAT/GRE'
  });
  return (
    <MainLayout
      noHeader
      hideSideCTA
      renderCustomHeader={() => <HeaderSchedulePage />}
      showFooterRegister={false}
    >
      <MySEO
        slug={location.pathname}
        breadCrumbSchema={breadCrumbList}
        h1="GMAT/GRE"
        featureImg="797o7eeYRD67BdOPlqvf"
        title="Tổng hợp khóa học luyện thi GMAT/GRE đảm bảo đầu ra tại DOL ENGLISH"
        description="Khoá học GMAT/GRE của DOL tập trung vào việc phát huy nền tảng tiếng Anh để tiếp cận kì thi GMAT/GRE hiệu quả, phát triển tư duy logic, tư duy phản biện để tăng điểm tối đa. Từ đó, cấp tốc đạt mục tiêu GMAT/GRE chỉ sau 1 khóa học."
      />
      <HeaderWithPromotion level="GMAT/GRE" />
      <Layout level="GMAT/GRE" />
    </MainLayout>
  );
};

export default React.memo(ListCourse);
