import * as React from 'react';

/* eslint-disable react/prop-types */
import { withHomePageSection } from 'components/withHomePageSection';
import { colorsV2 } from 'style/colors-v2';
import { CourseHeaderWithVideo } from 'shared/CourseLandingV3/CourseHeaderWithVideo';
import { graphql, useStaticQuery } from 'gatsby';

const IeltsNangCaoHeaderBase = ({ data }) => {
  return <CourseHeaderWithVideo data={data} isIelts={true} />;
};

export const IeltsNangCaoHeader = withHomePageSection(
  () => {
    const data = useStaticQuery(graphql`
      {
        gcms {
          jsonContanier(where: { name: "IeltsLanding_IeltsNangCao_Header" }) {
            content
          }
        }
      }
    `);
    return <IeltsNangCaoHeaderBase data={data.gcms.jsonContanier.content} />;
  },
  {
    style: {
      backgroundColor: colorsV2.paper
    }
  },
  {}
);
