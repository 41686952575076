import * as React from 'react';
import styled from 'styled-components';
import { Typography } from 'components/DesignSystemV2/Typography';
import { colorsV2 } from 'style/colors-v2';
import { Asterisk } from 'shared/Asterisk/Asterisk';

const LabelSwitch = styled.div`
  display: grid;
  grid-auto-flow: column;
  column-gap: 8px;
`;

const Label = styled.label`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  color: ${p => colorsV2?.[p.$color] || p.$color || colorsV2.black60};
  cursor: inherit;
`;

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
`;

export const InputItemLabel = props => {
  const {
    text,
    switchContent,
    rightActionContent,
    color,
    required = false,
    hasColon = false
  } = props;

  return (
    <Container>
      <Label $color={color}>
        <LabelSwitch>
          <Typography variant="medium/14-20">{text}</Typography>
          {switchContent}
        </LabelSwitch>
        {required && (
          <Typography variant="semi-bold/14-20">
            <Asterisk />
          </Typography>
        )}
        {hasColon && <Typography variant="medium/14-20">:</Typography>}
      </Label>
      {rightActionContent}
    </Container>
  );
};
