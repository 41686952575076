import React from 'react';
import PropTypes from 'prop-types';

import styled from 'styled-components';

import { fromScreen } from '../../utils/media-query/responsive.util';
import { ItemHOF } from './ItemHOF';

const List = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-row-gap: 32px;
  grid-column-gap: 24px;

  .itemWrp {
    width: 100%;
  }

  ${fromScreen(776)} {
    grid-template-columns: 1fr 1fr;
  }

  ${fromScreen(1144)} {
    grid-template-columns: 1fr 1fr 1fr;
  }
`;

const ListDetailCourse = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-column-gap: 24px;

  .itemWrp {
    width: 100%;
  }

  ${fromScreen(776)} {
    grid-template-columns: 1fr 1fr;
  }
`;

const ListItem = ({ hallOfFameList, isDetailCourse }) => {
  const Wrapper = isDetailCourse ? ListDetailCourse : List;
  return (
    <Wrapper isDetailCourse={isDetailCourse}>
      {hallOfFameList.map(i => {
        if (i.bangVangAvatar)
          return (
            <div className="itemWrp" key={i.id}>
              <ItemHOF {...i} />
            </div>
          );
      })}
    </Wrapper>
  );
};

ListItem.propTypes = {
  hallOfFameList: PropTypes.arrayOf(PropTypes.shape({}))
};

export default ListItem;
